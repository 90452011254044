import type { JSX, Component } from 'solid-js';
import { IconProps, DynamicIcon } from '~/components/designer2/DynamicIcon';

type Component<P = { object }> = (props: P) => JSX.Element;

// *INDENT-OFF*
/*
 * If we need more functionality, we can put div around selected svg icons:
 * 		<div
			id={icon.sigGetId()}
			class={icon.sigGetClass()}
			style={'width: '+icon.sigGetWidth()+'px;height: '+icon.sigGetHeight()+'px;max-width: '+icon.sigGetWidth()+'px;max-height: '+icon.sigGetHeight()+'px;'}
			onClick={icon.sigGetOnClick()}
			title={icon.sigGetTitle()}
							>
			... svg now needs width and height 100%
		</div>
Make sure to include
classCSS={'max-w-['+band.sigGetDim()+'px] max-h-['+band.sigGetDim()+'px] w-['+band.sigGetDim()+'px] h-['+band.sigGetDim()+'px] shrink'}
to allow the div to participate in flex, the way svg does
 */
// *INDENT-ON*

export const MenuIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 24, 24, 'Menu');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 16 16"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M0 3.75A.75.75 0 0 1 .75 3h14.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 3.75M0 8a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 
				1 0 8m.75 3.5a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5z"
        clip-rule="evenodd"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const CloseIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 800, 800, 'Close');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        fill="#000000"
        d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 
				1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const UserIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 27, 27, 'User Profile');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 27 27"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M13.5 2.25C16.6016 2.25 19.125 4.77338 19.125 7.87612C19.125 10.9789 16.6016 13.5011 13.5 13.5011C10.3984 13.5011 7.875 10.9766
					7.875 7.87612C7.875 4.77562 10.3984 2.25 13.5 2.25ZM13.5 0C9.15075 0 5.625 3.52575 5.625 7.87612C5.625 12.2265 9.15075 15.7511
					13.5 15.7511C17.8493 15.7511 21.375 12.2242 21.375 7.87612C21.375 3.528 17.8493 0 13.5 0ZM20.6651 15.0221C20.106 15.5824 19.476
					16.0695 18.7999 16.4869C22.0309 18.5951 23.7251 22.2052 24.3934 24.7511H2.583C3.2265 22.1749 4.89825 18.5602 8.17762
					16.4734C7.49925 16.0526 6.87038 15.561 6.31238 14.9974C1.52213 18.4534 0 24.5666 0 27H27C27 24.5903 25.38 18.4984 20.6651 15.0221Z"
        fill="currentColor"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const CartIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 20, 26, 'Shopping Cart');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 20 26"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M14.4444 6.5V4.33333C14.4444 1.94025 12.4544 0 10 0C7.54556 0 5.55556 1.94025 5.55556 4.33333V6.5H0V26H20V6.5H14.4444ZM6.66667
					4.33333C6.66667 2.5415 8.16222 1.08333 10 1.08333C11.8378 1.08333 13.3333 2.5415 13.3333 4.33333V6.5H6.66667V4.33333ZM17.7778
					23.8333H2.22222V8.66667H5.55556V10.2917C5.55556 10.5907 5.80444 10.8333 6.11111 10.8333C6.41778 10.8333 6.66667 10.5907 6.66667
					10.2917V8.66667H13.3333V10.2917C13.3333 10.5907 13.5822 10.8333 13.8889 10.8333C14.1956 10.8333 14.4444 10.5907 14.4444
					10.2917V8.66667H17.7778V23.8333Z"
        fill="currentColor"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const TrashIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 800, 800, 'Trash');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-3 0 32 32"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-261.000000, -205.000000)" fill="#000000">
          <path
            d="M268,220 C268,219.448 268.448,219 269,219 C269.552,219 270,219.448 270,220 L270,232 C270,232.553 269.552,233 269,233 
						C268.448,233 268,232.553 268,232 L268,220 L268,220 Z M273,220 C273,219.448 273.448,219 274,219 C274.552,219 275,219.448 275,220 L275,232 
						C275,232.553 274.552,233 274,233 C273.448,233 273,232.553 273,232 L273,220 L273,220 Z M278,220 C278,219.448 278.448,219 279,219 
						C279.552,219 280,219.448 280,220 L280,232 C280,232.553 279.552,233 279,233 C278.448,233 278,232.553 278,232 L278,220 L278,220 Z M263,233 
						C263,235.209 264.791,237 267,237 L281,237 C283.209,237 285,235.209 285,233 L285,217 L263,217 L263,233 L263,233 Z M277,209 L271,209 L271,208 
						C271,207.447 271.448,207 272,207 L276,207 C276.552,207 277,207.447 277,208 L277,209 L277,209 Z M285,209 L279,209 L279,207 C279,205.896 
						278.104,205 277,205 L271,205 C269.896,205 269,205.896 269,207 L269,209 L263,209 C261.896,209 261,209.896 261,211 L261,213 C261,214.104 
						261.895,214.999 262.999,215 L285.002,215 C286.105,214.999 287,214.104 287,213 L287,211 C287,209.896 286.104,209 285,209 L285,209 Z"
          />
        </g>
      </g>
    </svg>
    // *INDENT-ON*
  );
};

export const ClearIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 800, 800, 'Clear');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 7.68 7.68"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        style="fill:#000000;stroke-width:0.03"
        d="M 3.5128974,7.0739157 C 
		3.0006618,6.8146617 2.5166781,6.511065 1.9827853,6.1140963 1.2298194,5.554239 
		0.61789752,4.9317636 0.61789752,4.725669 c 0,-0.1094283 0.0813615,-0.2423592 
		0.1793534,-0.2930325 C 0.8458367,4.4075118 0.99073319,4.3802055 
		1.1192431,4.3719561 1.6689292,4.3366698 2.0536457,4.2305511 2.6299062,3.9552609 
		2.8056373,3.871311 3.0870136,3.6129141 3.6328974,3.0341815 3.9962994,2.6489118 
		4.2249051,2.5172339 4.6325802,2.4583598 l 0.2496831,-0.036058 0.4449576,-0.855 C 
		5.985117,0.30313514 5.9964714,0.28302652 6.0717462,0.24872894 c 
		0.1012587,-0.0461365 0.198537,-0.0386434 0.3428487,0.0264088 0.070566,0.0318096 
		0.2430525,0.10136222 0.3833025,0.15456143 0.4626548,0.17549285 
		0.5999999,0.27766757 0.5999999,0.4463561 0,0.0776359 -0.1805022,0.49160023 
		-0.3720734,0.85331403 C 6.871497,2.0207605 6.3799158,2.966087 
		6.2957997,3.1332289 c -0.036123,0.071778 -0.033488,0.095116 0.023307,0.2064444 
		0.1030401,0.2019746 0.1481241,0.3881132 0.1483836,0.6126287 2.1e-4,0.1770957 
		-0.025159,0.2828595 -0.1618707,0.675 -0.089162,0.25575 -0.2247774,0.62025 
		-0.3013665,0.81 -0.16839,0.4171863 -0.6024933,1.3036005 -0.6847311,1.3981821 
		-0.0326,0.037494 -0.074797,0.099366 -0.093771,0.1374942 -0.044645,0.089716 
		-0.2853369,0.2467818 -0.4714689,0.3076622 -0.1016235,0.033239 
		-0.2397513,0.046025 -0.4537416,0.042001 L 3.9928974,7.3168547 Z M 
		4.4378772,6.84504 C 4.501116,6.8274798 4.6487178,6.7566003 4.765881,6.6875301 
		4.9315395,6.5898708 4.9986342,6.5286345 5.0676351,6.4121247 5.1684456,6.2419032 
		5.4172476,5.7490176 5.4883488,5.5786758 5.5357422,5.465133 5.5357173,5.465025 
		5.4543372,5.4317007 5.1543255,5.3088489 4.9622823,5.205711 4.6736265,5.0124156 
		4.2595881,4.7351589 3.9636909,4.5435057 3.5776584,4.3025541 3.4104591,4.1981925 
		3.264843,4.09854 3.254067,4.081104 3.2423478,4.0621422 3.1517737,4.1156814 
		3.0286858,4.2143283 2.7769255,4.4160978 2.7791476,4.4149521 2.3760895,4.5508446 
		2.1394138,4.630641 1.936704,4.6742391 1.6410896,4.7089266 c -0.2245057,0.026344 
		-0.4264546,0.0645 -0.4487755,0.084791 -0.057092,0.051901 -0.020131,0.1413645 
		0.083758,0.2027334 0.048538,0.028672 0.2118378,0.1592493 0.3628878,0.2901711 
		0.2491061,0.2159115 0.283435,0.236316 0.369287,0.2194962 0.1607937,-0.031502 
		0.3228446,-0.1313784 0.6328183,-0.3900231 0.3306774,-0.2759202 
		0.4388005,-0.31314 0.5026399,-0.1730277 0.031755,0.069695 0.023551,0.08592 
		-0.1023951,0.2025 -0.2487775,0.2302764 -0.4900363,0.427764 -0.6326591,0.5178765 
		-0.07735,0.048871 -0.1362562,0.1020783 -0.130902,0.1182369 0.00535,0.016159 
		0.082953,0.072272 0.1724418,0.1246962 0.089489,0.052424 0.1943208,0.1291422 
		0.2329599,0.1704846 l 0.070253,0.075168 0.1007844,-0.059464 C 
		2.9096192,6.0598602 3.0487606,5.9226714 3.1633906,5.7877017 3.2780208,5.652732 
		3.417054,5.5000998 3.4723533,5.4485196 c 0.055299,-0.05158 0.1455159,-0.1427052 
		0.2004813,-0.2025 0.1452735,-0.158037 0.3050628,-0.1303587 0.3050628,0.052842 
		0,0.046123 -0.3967716,0.5082519 -0.7305069,0.8508375 -0.2091155,0.2146614 
		-0.2090933,0.214776 0.070507,0.3610953 0.09075,0.047491 0.2232639,0.1190085 
		0.2944749,0.1589274 l 0.1294752,0.07258 0.1373247,-0.15 c 0.075529,-0.0825 
		0.232368,-0.27825 0.3485316,-0.435 C 4.3438678,6.0005518 4.4570677,5.8612906 
		4.479259,5.8478326 4.501451,5.8343746 4.561055,5.7499226 4.6117129,5.6601613 
		4.7110719,5.484106 4.7962102,5.4375286 4.9086568,5.497708 c 0.056555,0.030268 
		0.063959,0.053319 0.050162,0.1561797 -0.010145,0.075633 -0.048716,0.1557072 
		-0.1033425,0.2145426 -0.085596,0.09219 -0.3479736,0.4421127 -0.4865343,0.6488715 
		-0.038701,0.05775 -0.1250799,0.162375 -0.1919517,0.2325 l -0.1215855,0.1275 
		0.1337463,-1.8e-4 c 0.073561,-9e-5 0.1854873,-0.014535 0.2487261,-0.032095 z M 
		5.8174992,4.7394132 C 5.8805301,4.5705021 5.9611035,4.3288758 5.9965515,4.202466 
		6.0554241,3.992523 6.0576219,3.9562632 6.0219453,3.7835346 5.9766465,3.5642184 
		5.8727445,3.3720757 5.7378567,3.2581768 5.6856291,3.2140759 5.503479,3.1101175 
		5.333079,3.0271582 5.0400945,2.8845186 5.0107536,2.8764615 4.793079,2.8788716 c 
		-0.3340821,0.0037 -0.5175312,0.094044 -0.8277123,0.4076309 -0.3235263,0.3270788 
		-0.429015,0.4689461 -0.373977,0.5029469 0.022829,0.014103 0.1138854,0.070015 
		0.202347,0.1242474 0.2059683,0.1262721 0.8004537,0.5138286 1.1999787,0.7822893 
		0.2690715,0.1808025 0.5812683,0.3408933 0.679182,0.3482763 0.0165,0.00124 
		0.081571,-0.1359378 0.1446018,-0.3048492 z M 6.4178751,1.973394 C 
		6.6616341,1.5044849 6.8726577,1.0804397 6.8868165,1.0310714 6.9157575,0.93015981 
		6.9536235,0.95372012 6.5045592,0.79323069 L 6.2562213,0.70447803 
		6.1848402,0.82839009 C 5.9969211,1.1546024 5.3272458,2.486058 
		5.3345778,2.5188913 5.344893,2.5650859 5.8972947,2.8556502 5.9421084,2.8384536 
		5.9600211,2.8315799 6.1741161,2.4423031 6.4178751,1.973394 Z M 
		1.9907746,3.2903404 C 1.7647441,3.1676977 1.7684101,2.8109746 
		1.9969155,2.6928099 2.1691527,2.6037425 2.3783139,2.6637354 2.4623897,2.8263201 
		2.6224157,3.1357765 2.2966431,3.4563027 1.9907746,3.2903404 Z m 
		-1.43999995,-0.09 C 0.3247441,3.0776977 0.32841018,2.7209746 
		0.55691553,2.6028099 0.72915278,2.5137425 0.93831389,2.5737354 
		1.0223897,2.7363201 1.1824158,3.0457765 0.85664309,3.3663028 
		0.55077465,3.2003404 Z m 1.85999995,-1.29 C 2.184744,1.7876976 
		2.1884101,1.4309746 2.4169155,1.3128099 2.5891527,1.2237426 2.7983138,1.2837354 
		2.8823896,1.4463201 3.0424156,1.7557763 2.716643,2.0763029 2.4107746,1.9103404 
		Z"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const ResetSettingsIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 800, 800, 'Reset Settings');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 70.90833 70.908335"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        style="fill:#000000;stroke-width:0.352778"
        d="m 50.773481,70.441458 c -4.298964,-0.861346 -8.937931,-4.504196 
				-10.955964,-8.60341 -4.815609,-9.781885 1.297336,-21.457156 12.105402,-23.120422 
				5.603649,-0.862355 11.012706,1.438628 14.4306,6.138693 l 1.000516,1.375847 v 
				-1.663622 c 0,-2.099162 0.487091,-3.02835 1.5875,-3.02835 1.324469,0 
				1.5875,0.985654 1.5875,5.948831 0,3.696998 -0.10728,4.691383 -0.554365,5.138469 
				-0.814959,0.814959 -10.070754,0.814959 -10.885713,0 -0.655845,-0.655846 
				-0.661472,-0.702998 -0.189177,-1.585493 0.321564,-0.600847 0.764071,-0.682363 
				3.704167,-0.682363 1.836437,0 3.338977,-0.0857 3.338977,-0.190444 0,-0.667127 
				-1.730431,-3.357538 -3.119539,-4.850147 -2.338525,-2.512769 -5.141609,-3.778215 
				-8.369903,-3.778575 -3.266203,-3.52e-4 -5.662315,0.745265 -7.972054,2.480769 
				-2.11184,1.586805 -4.296762,4.592249 -4.947154,6.804994 -0.238238,0.810525 
				-0.426194,2.68 -0.417678,4.154389 0.02723,4.714483 2.626078,8.982395 
				6.835965,11.226242 1.254333,0.668552 3.165277,1.308953 4.645399,1.556773 
				2.739015,0.458604 3.606884,1.13588 2.900288,2.263358 -0.452808,0.722524 
				-2.354608,0.889353 -4.724767,0.414461 z M 26.255428,67.665023 c 
				-1.516596,-0.62073 -1.930708,-1.579792 -2.534257,-5.869223 -0.325358,-2.312321 
				-0.70292,-4.250881 -0.839026,-4.307907 -0.136107,-0.05703 -1.137766,-0.639801 
				-2.22591,-1.295047 l -1.978442,-1.191355 -3.880078,1.559627 c -2.134042,0.857796 
				-4.112542,1.55963 -4.396666,1.55963 -1.5718676,0 -2.3967526,-0.975572 
				-5.7879568,-6.845282 C 2.6966438,47.958357 1.0234379,44.908142 
				0.89485665,44.497205 0.46834309,43.134104 1.3089953,41.984447 
				4.7149339,39.272948 L 8.0873734,36.588122 V 34.028756 31.469391 L 
				4.7727097,28.905406 C 0.99481397,25.983099 0.36174469,24.981583 
				1.1367408,23.153305 2.0573052,20.98162 7.7529825,11.388752 8.4949538,10.760335 c 
				1.0998503,-0.9315251 2.4701642,-0.757589 6.2533662,0.79375 1.892678,0.776111 
				3.61749,1.411111 3.832914,1.411111 0.215425,0 1.33015,-0.559042 
				2.477166,-1.242317 l 2.085485,-1.242316 0.577032,-4.0564715 c 0.645298,-4.536376 
				0.951976,-5.3385642 2.226148,-5.82300337 0.485903,-0.18474012 
				4.118648,-0.33589112 8.072767,-0.33589112 9.189533,0 8.76288,-0.26101647 
				9.65817,5.90860819 0.344876,2.3766097 0.693385,4.3666708 0.77446,4.4223588 
				1.311184,0.900602 4.004927,2.369032 4.345841,2.369032 0.246063,0 2.008018,-0.635 
				3.915456,-1.411111 3.977915,-1.6185591 5.082663,-1.72684 6.298684,-0.617361 
				1.085893,0.990746 7.251047,11.656992 7.522594,13.014728 0.283475,1.417372 
				-0.384069,2.2509 -4.063999,5.074514 l -3.206126,2.460062 -0.01238,1.781528 c 
				-0.01697,2.439182 -0.322069,2.652659 -3.417394,2.391007 -1.53877,-0.130073 
				-3.644229,-0.03544 -5.22406,0.234795 -3.34138,0.57156 -3.569274,0.405345 
				-3.796393,-2.768937 -0.429499,-6.002825 -5.0462,-11.070755 -10.96217,-12.033604 
				-5.604632,-0.912177 -11.554198,2.153797 -14.00544,7.217386 -3.067733,6.337091 
				-0.584428,14.136185 5.52986,17.367135 2.215415,1.170686 5.692856,2.04216 
				6.776462,1.698237 1.176751,-0.373489 1.894437,0.737143 1.561722,2.416785 
				-0.158107,0.79817 -0.280842,3.197472 -0.272743,5.331777 0.01597,4.209337 
				0.668108,6.712137 2.450874,9.406078 1.061293,1.603721 1.127263,2.610696 
				0.203426,3.105122 -0.804379,0.430487 -10.801512,0.457242 -11.841246,0.03168 z m 
				8.111214,-3.458859 C 32.603407,59.929775 31.908046,55.8949 32.362108,52.574725 l 
				0.299505,-2.190019 -1.936608,-0.40204 c -4.36952,-0.907108 -7.936542,-3.237547 
				-10.243661,-6.692486 -6.655058,-9.966029 -1.029643,-23.062366 10.7758,-25.086775 
				8.294283,-1.422312 16.974203,4.693585 18.355624,12.933431 0.173034,1.032096 
				0.474158,1.745709 0.727915,1.725025 0.23804,-0.0194 1.702798,-0.05909 
				3.25502,-0.08819 l 2.822222,-0.05292 v -1.331235 c 0,-1.264762 
				0.167901,-1.461113 3.362501,-3.932224 1.849378,-1.430544 3.450184,-2.742858 
				3.557348,-2.916255 0.164186,-0.26566 -4.963008,-9.873535 -5.921795,-11.096881 
				-0.227951,-0.290846 -1.416162,0.0282 -4.26999,1.146528 -4.431414,1.736536 
				-5.093631,1.830287 -6.293682,0.89101 -0.462728,-0.362176 -1.816199,-1.149472 
				-3.007712,-1.749545 L 41.678208,12.641105 41.245716,9.5399562 C 
				40.368809,3.2521307 40.519467,3.6559557 38.946434,3.3768557 38.175269,3.240029 
				35.224777,3.1189312 32.389785,3.1077495 l -5.15453,-0.020331 -0.601553,3.9687497 
				c -0.869768,5.7382908 -0.745116,5.4615308 -2.978246,6.6125048 -1.092376,0.563019 
				-2.462389,1.348392 -3.044472,1.745273 -0.582084,0.39688 -1.337956,0.722646 
				-1.679716,0.723925 -0.34176,0.0013 -2.394098,-0.710233 -4.560751,-1.581137 
				-2.166652,-0.870904 -3.976448,-1.545592 -4.021768,-1.499305 -0.293648,0.299907 
				-6.4920013,10.956271 -6.4808673,11.142068 0.00761,0.127036 1.079175,1.066033 
				2.3812498,2.086661 1.3020748,1.020627 2.8989677,2.299287 3.5486514,2.841467 l 
				1.1812431,0.985782 0.02201,4.000957 c 0.0194,3.526828 -0.05676,4.058552 
				-0.642667,4.486976 -2.8511526,2.084814 -6.5294854,5.142662 -6.4387329,5.352604 
				0.7023321,1.624757 6.1940409,10.978863 6.4488379,10.984398 0.187451,0.0041 
				2.21238,-0.720654 4.499844,-1.610501 l 4.159025,-1.617905 2.202934,1.390424 c 
				1.211613,0.764734 2.56435,1.50483 3.006081,1.644661 1.246462,0.394571 
				1.720656,1.511752 2.18154,5.139615 0.694138,5.463917 0.215804,4.938889 
				4.499632,4.938889 3.389087,0 3.682045,-0.05244 3.449116,-0.617361 z"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const CopyIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 800, 800, 'Copy');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M9 9V6.2002C9 5.08009 9 4.51962 9.21799 4.0918C9.40973 3.71547 9.71547 3.40973 10.0918 3.21799C10.5196 3 11.0801 3 12.2002 
				3H17.8002C18.9203 3 19.4801 3 19.9079 3.21799C20.2842 3.40973 20.5905 3.71547 20.7822 4.0918C21.0002 4.51962 21.0002 5.07967 21.0002 
				6.19978V11.7998C21.0002 12.9199 21.0002 13.48 20.7822 13.9078C20.5905 14.2841 20.2839 14.5905 19.9076 14.7822C19.4802 15 18.921 15 17.8031 
				15H15M9 9H6.2002C5.08009 9 4.51962 9 4.0918 9.21799C3.71547 9.40973 3.40973 9.71547 3.21799 10.0918C3 10.5196 3 11.0801 3 12.2002V17.8002C3 
				18.9203 3 19.4801 3.21799 19.9079C3.40973 20.2842 3.71547 20.5905 4.0918 20.7822C4.5192 21 5.07899 21 6.19691 21H11.8036C12.9215 21 13.4805 21 
				13.9079 20.7822C14.2842 20.5905 14.5905 20.2839 14.7822 19.9076C15 19.4802 15 18.921 15 17.8031V15M9 9H11.8002C12.9203 9 13.4801 9 13.9079 
				9.21799C14.2842 9.40973 14.5905 9.71547 14.7822 10.0918C15 10.5192 15 11.079 15 12.1969L15 15"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const SubmitIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 800, 800, 'Submit');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M905.92 237.76a32 32 0 0 0-52.48 36.48A416 416 0 1 1 96 512a418.56 418.56 0 0 1 297.28-398.72 32 32 0 1 0-18.24-61.44A480 480 0 1 0 992 
				512a477.12 477.12 0 0 0-86.08-274.24z"
        fill="#000000"
      />
      <path
        d="M630.72 113.28A413.76 413.76 0 0 1 768 185.28a32 32 0 0 0 39.68-50.24 476.8 
				476.8 0 0 0-160-83.2 32 32 0 0 0-18.24 61.44zM489.28 86.72a36.8 36.8 0 0 0 10.56 6.72 30.08 30.08 0 0 0 24.32 0 37.12 37.12 0 0 0 10.56-6.72A32 
				32 0 0 0 544 64a33.6 33.6 0 0 0-9.28-22.72A32 32 0 0 0 505.6 32a20.8 20.8 0 0 0-5.76 1.92 23.68 23.68 0 0 0-5.76 2.88l-4.8 3.84a32 32 0 0 0-6.72 
				10.56A32 32 0 0 0 480 64a32 32 0 0 0 2.56 12.16 37.12 37.12 0 0 0 6.72 10.56zM230.08 467.84a36.48 36.48 0 0 0 0 51.84L413.12 704a36.48 36.48 
				0 0 0 51.84 0l328.96-330.56A36.48 36.48 0 0 0 742.08 320l-303.36 303.36-156.8-155.52a36.8 36.8 0 0 0-51.84 0z"
        fill="#000000"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const WearshareLogo: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 362.062, 41.306, 'WEARSHARE HOME');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 362.062 41.306"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        stroke-width="0"
        d="m158.595,26.217c2.574,3.734,5.12,7.399,7.627,11.089.511.752,1.561,1.582.81,2.496-.417.506
				-1.554.539-2.375.573-1.875.078-3.756.001-5.633.033-1.18.02-2.114-.414-2.765-1.373-2.332-3.43-4.651-6.869-6.946-10.323-.254-.382
				-.443-.885-.455-1.337-.059-2.192-.058-4.387-.055-6.58.001-.965.532-1.46,1.492-1.501.718-.03,1.439-.021,2.153-.091,2.861-.282,
				5.046-2.42,5.007-4.871-.043-2.702-2.033-4.766-5.019-4.942-2.389-.141-4.793-.038-7.191-.043-.354,0-.709,0-1.258,0v1.456c0,
				9.057,0,18.113,0,27.17,0,2.109-.318,2.426-2.415,2.427-1.758,0-3.517.014-5.275-.005-1.31-.014-1.738-.417-1.739-1.726-.011
				-12.009-.011-24.018,0-36.027.001-1.338.401-1.712,1.724-1.714,5.634-.008,11.27-.06,16.903.019,3.36.047,6.602.666,
				9.284,2.94,7.021,5.951,5.471,17.82-2.851,21.844-.314.152-.629.3-1.021.487Z"
      />
      <path
        stroke-width="0"
        d="m315.577,26.211c2.62,3.808,5.173,7.518,7.726,11.228.181.263.45.503.524.794.14.547.351,1.191.175,1.662-.109.294
				-.869.473-1.342.483-2.237.049-4.475.01-6.713.027-1.126.008-2.042-.399-2.67-1.323-2.354-3.463-4.703-6.931-7.009-10.427-.29-.439
				-.425-1.048-.443-1.585-.069-2.071-.089-4.146-.071-6.218.008-.982.55-1.543,1.581-1.565.718-.016,1.44-.01,2.152-.09,2.846
				-.317,4.932-2.366,4.928-4.803-.004-2.686-1.842-4.753-4.822-4.964-2.815-.199-5.654-.043-8.657-.043,0,.526,0,.988,0,1.45,0,
				9.097.001,18.193,0,27.29,0,1.971-.308,2.27-2.299,2.272-1.758.002-3.517.01-5.275-.003-1.395-.01-1.815-.406-1.816-1.761-.007-12.009
				-.007-24.018,0-36.027,0-1.255.407-1.676,1.635-1.678,5.714-.011,11.43-.069,17.143.019,3.94.061,7.591,
				1.032,10.375,4.112,4.528,5.008,5.109,15.801-3.92,20.56-.352.185-.714.352-1.2.59Z"
      />
      <path
        stroke-width="0"
        d="m176.579,26.828c1.039,0,2.079.003,3.118,0,1.077-.004,1.709.443,1.845,1.587.242,2.036,1.454,3.323,3.355,
				3.959,2.253.754,4.55.835,6.838.181,1.706-.487,2.581-1.621,2.659-3.278.071-1.503-.528-2.486-2.276-3.111-1.759-.628-3.628
				-.943-5.4-1.541-2.977-1.004-6.04-1.871-8.847-3.243-7.569-3.7-7.138-14.415-.753-18.562,6.368-4.136,16.812-3.703,
				22.498,1.179,2.224,1.909,3.268,4.379,3.366,7.265.048,1.415-.349,1.816-1.735,1.829-1.879.018-3.758-.019-5.636.015-.928.017-1.558
				-.337-1.814-1.224-1.257-4.354-8.025-4.585-10.52-2.72-1.514,1.132-1.745,3.013-.267,4.174,1.166.915,2.554,1.64,3.953,
				2.146,2.395.867,4.88,1.486,7.319,2.233.914.28,1.817.605,2.709.95,4.121,1.596,6.682,4.432,6.954,8.978.284,4.738-1.265,8.624
				-5.472,11.187-5.523,3.365-16.672,3.812-22.969-1.939-2.432-2.222-3.69-4.958-3.767-8.235-.034-1.464.338-1.816,1.844-1.83.999-.009,1.999
				-.002,2.998-.001Z"
      />
      <path
        stroke-width="0"
        d="m267.391,12.723c-1.419,4.218-2.817,8.443-4.264,12.652-1.566,4.555-3.172,9.096-4.772,13.639-.347.986-1.073,
				1.409-2.125,1.393-2.037-.029-4.075.005-6.113-.013-1.374-.012-1.738-.516-1.259-1.808,4.426-11.948,8.86-23.894,
				13.306-35.835.574-1.541,1.116-1.863,2.802-1.867,1.638-.004,3.276-.002,4.914,0,1.993.001,2.499.335,3.192,2.191,1.336,3.581,
				2.659,7.167,3.983,10.752,2.993,8.106,5.986,16.212,8.973,24.32.692,1.878.421,2.25-1.6,2.251-1.798.001-3.598-.047-5.393.016
				-1.328.047-2.086-.513-2.476-1.758-.392-1.253-.929-2.461-1.299-3.719-.242-.822-.633-1.125-1.513-1.105-2.596.059-5.194.023
				-7.791.019-.388,0-.776-.034-1.304-.058.925-2.695,1.789-5.287,2.731-7.849.1-.271.654-.508,1.011-.529,1.142-.067,2.29-.025,3.615
				-.025-1.475-4.341-2.89-8.504-4.305-12.667-.104,0-.209,0-.313,0Z"
      />
      <path
        stroke-width="0"
        d="m107.605,33.772c.927-2.699,1.781-5.262,2.713-7.798.102-.277.635-.538.982-.558,1.19-.068,2.387-.026,3.727
				-.026-1.485-4.372-2.925-8.611-4.451-13.102-1.543,4.519-2.988,8.776-4.45,13.027-1.515,4.406-3.04,8.809-4.574,13.209-.558,1.6
				-.961,1.87-2.623,1.873-1.838.004-3.676.005-5.515,0-1.724-.005-2.041-.47-1.428-2.129,2.086-5.64,4.179-11.277,
				6.271-16.914,2.231-6.012,4.465-12.022,6.694-18.034.807-2.177,1.175-2.437,3.497-2.437,1.598,0,3.197-.005,4.795.001,1.597.006,
				2.147.365,2.748,1.957,1.322,3.502,2.606,7.018,3.905,10.529,3.053,8.255,6.106,16.509,9.157,24.765.687,1.859.402,2.261-1.596,
				2.263-1.798.001-3.599-.053-5.394.018-1.334.052-2.095-.522-2.491-1.748-.391-1.213-.876-2.396-1.239-3.617-.264-.888
				-.685-1.257-1.688-1.226-2.715.084-5.434.03-8.151.025-.234,0-.468-.04-.89-.078Z"
      />
      <path
        stroke-width="0"
        d="m67.081,9.346v22.589c.513,0,.976,0,1.44,0,5.716,0,11.433-.002,17.149.002,1.918.001,2.255.331,
				2.265,2.204.008,1.477.021,2.954-.006,4.43-.026,1.38-.446,1.821-1.789,1.823-8.954.011-17.908.011-26.863,0-1.446-.002-1.84
				-.481-1.841-2.152-.004-10.257-.002-20.514-.002-30.771,0-1.596-.019-3.193.007-4.789.023-1.366.453-1.792,1.807-1.793,8.634-.007,
				17.269-.007,25.903,0,1.298.001,1.745.419,1.775,1.702.037,1.596.026,3.193.006,4.789-.02,1.55-.445,1.961-2.03,1.963-5.476.008
				-10.953.003-16.429.003-.431,0-.862,0-1.392,0Z"
      />
      <path
        stroke-width="0"
        d="m341.2,9.346v22.527c.5.022.961.06,1.422.06,5.674.004,11.348,0,17.022.004,2.118.001,2.412.304,2.415,2.435.002,
				1.277.007,2.553-.001,3.83-.011,1.809-.389,2.195-2.162,2.196-8.711.003-17.422.002-26.133,0-1.872,0-2.202-.336-2.205-2.253-.005-3.152
				-.002-6.303-.002-9.455,0-8.498-.002-16.995.001-25.493,0-2.086.237-2.313,2.355-2.313,8.351,0,16.702-.001,25.054,0,1.662,0,
				2.059.373,2.088,2.016.025,1.396.015,2.793.003,4.189-.017,1.867-.401,2.253-2.241,2.255-5.394.004-10.789.001-16.183.001h-1.435Z"
      />
      <path
        stroke-width="0"
        d="m233.772,25.102h-9.111v-8.982h9.111v-1.421c0-3.752,0-7.504,0-11.255,0-2.356.208-2.559,2.612-2.56,1.599,0,
				3.198-.002,4.797,0,1.786.003,2.104.313,2.106,2.075.004,4.75.001,9.499.001,14.249,0,7.025.002,14.049-.001,21.074,0,1.731-.381,
				2.111-2.101,2.116-1.799.005-3.598.008-5.397-.001-1.549-.008-2.006-.445-2.011-1.994-.014-3.951-.005-7.903-.005-11.854,0-.431,
				0-.862,0-1.446Z"
      />
      <path
        stroke-width="0"
        d="m29.478,30.886c-1.128-3.572-2.215-7.017-3.42-10.832-.249.717-.393,1.1-.515,1.489-1.767,5.622-3.528,11.246-5.298,
				16.867-.532,1.689-.962,1.987-2.769,1.989-1.518.002-3.037.023-4.555-.008-1.191-.024-1.375-.291-1.078-1.455,1.28-5.017,2.479-10.056,
				3.872-15.041,1.971-7.05,4.081-14.06,6.145-21.084.479-1.631.899-1.924,2.541-1.927,1.199-.002,2.399.041,3.596-.012,1.051-.047,
				1.703.446,1.978,1.379,1.25,4.239,2.465,8.488,3.674,12.738.071.249.01.557-.058.82-1.256,4.817-2.522,9.631-3.793,14.444-.048.182
				-.171.343-.32.633Z"
      />
      <path
        stroke-width="0"
        d="m210.946,20.602c0-5.861-.002-11.723.001-17.584,0-1.83.314-2.133,2.174-2.135,1.877-.002,3.754-.012,5.631.004,
				1.272.011,1.633.364,1.662,1.645.029,1.275.001,2.552.01,3.828.037,5.699.107,11.397.109,17.096.002,5.02-.066,10.04-.113,15.059-.014,
				1.503-.405,1.874-1.95,1.881-1.797.008-3.594.006-5.391,0-1.735-.005-2.128-.385-2.129-2.092-.005-5.901-.002-11.802-.002-17.703Z"
      />
      <path
        stroke-width="0"
        d="m47.304.884c.999,0,1.998-.007,2.997.002,1.403.012,1.789.493,1.389,1.873-.809,2.792-1.659,5.572-2.503,
				8.355-2.762,9.108-5.527,18.214-8.292,27.321-.531,1.75-.813,1.963-2.601,1.965-1.439.001-2.878.03-4.315-.009-1.446-.039-2.045
				-.812-1.685-2.145,3.165-11.726,6.334-23.45,9.514-35.172.504-1.856.971-2.184,2.86-2.189.879-.002,1.758,0,2.637,0Z"
      />
      <path
        stroke-width="0"
        d="m8.886,31.638c-.747-2.419-1.502-4.836-2.24-7.258C4.512,17.375,2.384,10.368.257,3.36.154,3.019.03,2.67.007,
				2.319-.05,1.443.228.86,1.273.875c2.236.031,4.473.019,6.709.006,1.07-.006,1.767.537,2.058,1.501,1.186,3.924,2.335,7.858,
				3.471,11.797.093.322.037.72-.051,1.056-1.43,5.466-2.876,10.928-4.319,16.391-.084.004-.169.009-.253.013Z"
      />
      <path
        stroke-width="0"
        d="m71.175,15.811c1.815,0,3.563,0,5.311,0,2.158,0,4.316-.022,6.473.01,1.393.021,1.847.412,
				1.898,1.747.063,1.632.004,3.268-.002,4.903-.004,1.024-.557,1.593-1.543,1.6-4.024.029-8.049.012-12.137.012v-8.272Z"
      />
      <path
        stroke-width="0"
        d="m345.301,15.811c2.253,0,4.437,0,6.622,0,1.716,0,3.432-.026,5.147.01,1.434.03,1.867.459,1.894,1.867.031,
				1.592-.008,3.186-.006,4.779.001.956-.465,1.568-1.413,1.576-4.057.035-8.115.015-12.243.015v-8.247Z"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const WearshareBandLogo: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 400, 253, 'Wearshare');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 105.83333 66.939585"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        style="fill:#000000;stroke-width:0.264583"
        d="M 28.599628,66.937687 C 19.156762,66.356572 11.670573,63.978584 8.0646179,60.414742 7.2011332,59.561341 6.2411558,58.287926
				5.9105935,57.557414 4.9801462,55.501218 0.46377167,35.68939 0.45321611,33.617721 0.44221565,31.458734 1.0389196,29.459778 2.332913,27.320741
				7.9391739,18.053299 23.827807,9.108156 43.548587,4.1167245 60.43428,-0.15713206 77.389726,-0.75326255 88.79234,2.5260155 c 5.384657,1.5485738
				10.044533,4.7153127 11.5471,7.8471245 0.40202,0.837939 1.40785,4.604302 2.46271,9.221664 0.98072,4.292865 2.14406,9.29349 2.58519,11.1125
				0.7427,3.062571 0.77995,3.440099 0.50336,5.10213 -1.03159,6.198928 -8.007414,12.939919 -19.347319,18.696024 -13.723877,6.966204
				-31.096952,11.505571 -47.625003,12.44382 -5.060627,0.287277 -5.469721,0.286816 -10.31875,-0.01159 z m 5.876803,-3.607666 c 1.589342,-0.933415
				2.274009,-3.414432 1.405652,-5.093647 -0.496533,-0.960191 -1.639371,-1.645303 -3.975164,-2.383044 -1.018645,-0.32173 -2.060442,-0.771845
				-2.315104,-1.000252 -1.018349,-0.913368 -0.28063,-1.920774 1.406573,-1.920774 0.624168,0 1.075095,0.242858 1.719419,0.926042
				0.750416,0.795673 1.043487,0.926041 2.081763,0.926041 1.170641,0 1.208391,-0.02521 1.208391,-0.806889 0,-2.105041 -2.221,-3.695824
				-5.145177,-3.685217 -3.595892,0.01304 -5.833235,2.353077 -4.913718,5.13924 0.43042,1.304184 1.595826,2.104824 4.36014,2.995438
				2.487571,0.801452 3.127214,1.31567 2.627287,2.112116 -0.816005,1.299996 -3.41919,1.045144 -4.053886,-0.396875 -0.364558,-0.828276
				-0.435099,-0.859896 -1.918229,-0.859896 -1.476533,0 -1.539751,0.02787 -1.539751,0.678852 0,1.226939 1.088405,2.747222 2.487867,3.475051
				1.731566,0.900549 4.938078,0.848677 6.563937,-0.106186 z m 8.704705,-0.04712 c 0.08894,-0.09028 0.04417,-1.210776 -0.09949,-2.489997
				-0.143663,-1.279223 -0.216434,-2.370367 -0.161716,-2.424768 0.166056,-0.165087 4.021411,-0.651743 4.215062,-0.532061 0.09929,0.06136
				0.261668,0.977413 0.360847,2.035669 0.26503,2.827872 0.277344,2.850142 1.576258,2.850142 2.07328,0 2.047496,0.148074 1.194625,-6.860614
				-0.414898,-3.409534 -0.85779,-6.302581 -0.984202,-6.428994 -0.127863,-0.127862 -0.805225,-0.132061 -1.526678,-0.0095 -1.472753,0.25027
				-1.454886,0.190976 -1.06748,3.542954 l 0.202634,1.753258 -1.538912,0.175644 c -0.846405,0.0966 -1.850213,0.236291 -2.230687,0.310414
				-0.66036,0.128654 -0.699674,0.0711 -0.865724,-1.267441 -0.09567,-0.771218 -0.244377,-1.908231 -0.330454,-2.526694 l -0.156509,-1.124479
				-1.226728,0.004 c -0.6747,0.0021 -1.376629,0.09921 -1.559838,0.215524 -0.264247,0.167754 -0.192555,1.438585 0.346798,6.147509
				0.373949,3.264824 0.78836,6.202117 0.920911,6.527321 0.216837,0.531982 0.367795,0.57498 1.505286,0.428723 0.695354,-0.0894 1.337051,-0.236418
				1.425996,-0.326697 z M 56.571927,61.36898 c 0.542422,-0.19917 0.718077,-0.567722 0.92356,-1.937798 0.06128,-0.408553 0.55866,-0.633679
				2.450836,-1.109292 1.305679,-0.328189 2.38584,-0.573778 2.400358,-0.545753 0.41751,0.805982 1.321803,1.770393 1.658985,1.769274
				0.797817,-0.0026 2.429381,-0.546174 2.429381,-0.809308 0,-0.299169 -7.272928,-11.245135 -7.610214,-11.453587 -0.277744,-0.171656
				-2.478895,0.342844 -2.829772,0.661434 -0.259381,0.235517 -1.983475,13.643364 -1.780482,13.846361 0.132983,0.132982 1.525577,-0.11592
				2.357348,-0.421331 z m 1.247717,-6.487747 c 0.07255,-0.562589 0.204338,-1.600491 0.292849,-2.306453 l 0.16093,-1.283567 1.170395,1.880703 
				c 0.643718,1.034386 1.170395,1.924531 1.170395,1.978096 0,0.05357 -0.343297,0.166056 -0.762884,0.249973 -0.419584,0.08392 -1.078045,0.231678
				-1.463244,0.328356 -0.681548,0.171059 -0.696817,0.148305 -0.568441,-0.847108 z m 13.869785,2.386444 c 0.682188,-0.279197 0.846605,-0.471692
				0.707397,-0.828202 -1.426665,-3.653692 -3.305865,-9.130263 -3.194653,-9.310208 0.184862,-0.299112 2.45654,-1.07613 3.146153,-1.07613
				0.777422,0 1.495054,0.77247 1.495054,1.609305 0,0.692433 -0.460396,1.220224 -1.712465,1.963142 -0.416833,0.247327 -0.412949,0.351528
				0.05774,1.54981 0.416417,1.060114 0.846982,1.534028 2.463393,2.711405 1.077327,0.784717 2.080241,1.417526 2.228702,1.40625 0.630835,-0.04793
				2.890536,-0.962165 2.970408,-1.201783 0.04793,-0.14379 -0.837729,-0.899863 -1.96813,-1.680162 -1.1304,-0.780299 -2.08974,-1.44335
				-2.131867,-1.473446 -0.04212,-0.0301 0.235461,-0.63541 0.616858,-1.345142 1.623774,-3.021639 -0.328237,-6.585415 -3.575545,-6.527845
				-1.091317,0.01934 -6.946692,1.936631 -7.504849,2.457389 -0.171582,0.160084 3.692687,11.314523 4.24369,12.249653 0.131069,0.222443
				0.717949,0.08538 2.158114,-0.504036 z M 88.46161,49.903126 c 2.291954,-1.269666 4.167188,-2.362409 4.167188,-2.428319 0,-0.42558
				-1.128305,-2.214621 -1.394439,-2.211025 -0.178945,0.0024 -1.575509,0.710478 -3.103478,1.573469 -3.044518,1.719535 -2.926413,1.720744
				-3.854635,-0.03953 l -0.374722,-0.71062 2.511553,-1.357958 c 1.381355,-0.746877 2.511554,-1.518778 2.511554,-1.715334 0,-0.196556
				-0.208359,-0.723458 -0.463021,-1.170895 -0.456641,-0.802309 -1.043484,-1.029454 -1.534583,-0.593976 -0.138245,0.122586 -1.163833,0.715348
				-2.279084,1.317246 l -2.027732,1.094365 -0.533136,-0.9036 c -0.624588,-1.058598 -0.721984,-0.947243 2.207348,-2.523718 3.134807,-1.687054
				3.14556,-1.70003 2.437072,-2.940973 -0.321744,-0.563549 -0.731224,-1.025549 -0.909955,-1.026671 -0.4927,-0.0031 -8.036056,4.257456
				-8.173903,4.616686 -0.118894,0.309824 5.169495,10.168826 5.928101,11.051601 0.215892,0.251229 0.465915,0.416496 0.555609,0.367257
				0.08969,-0.04924 2.03831,-1.128342 4.330263,-2.398009 z M 6.6248045,48.257008 C 7.7682771,46.045763 11.36866,42.229697 14.672747,39.726947
				25.414036,31.590735 43.601702,24.888532 62.069421,22.261149 c 11.714301,-1.666582 23.918391,-1.36683 31.579992,0.775654 2.283497,0.638556
				2.185098,0.686239 3.896593,-1.888215 C 103.25562,12.560107 97.883376,5.4926888 83.71254,2.9500554 62.533257,-0.85008412 30.142278,6.5531883
				12.396974,19.249919 c -3.0643946,2.192569 -6.6074028,5.646343 -8.134658,7.929778 -1.3260468,1.982604 -2.3783562,4.73725 -2.3942474,6.267457
				-0.00946,0.911313 3.4987016,16.569423 3.7128075,16.571495 0.07276,7.94e-4 0.5425282,-0.792033 1.0439284,-1.761641 z M 20.570535,25.125116 
				c -2.297709,-2.778296 -4.234836,-5.200467 -4.304728,-5.382602 -0.07815,-0.203655 0.4034,-0.551609 1.250737,-0.903746 1.651983,-0.686534
				1.446956,-0.819091 4.234061,2.737475 1.074827,1.371564 2.019531,2.498866 2.099341,2.505115 0.07981,0.0062 -0.06846,-1.614214
				-0.329498,-3.601031 -0.514769,-3.918062 -0.483,-4.086805 0.857398,-4.55407 0.923586,-0.321964 0.859821,-0.375865 3.630626,3.069058
				1.091969,1.357641 2.021281,2.432558 2.065134,2.388705 0.04385,-0.04385 -0.09018,-1.446631 -0.297853,-3.117286 -0.563319,-4.531706
				-0.601027,-4.331344 0.935326,-4.969825 0.828583,-0.344342 1.378863,-0.448655 1.482469,-0.28102 0.169741,0.274649 1.424231,11.437033
				1.429956,12.723683 0.0024,0.532312 -0.200929,0.826373 -0.777404,1.124479 -0.429336,0.222017 -0.994883,0.401373 -1.256771,0.398569
				-0.273319,-0.0029 -1.377836,-1.078171 -2.592826,-2.524108 -1.38982,-1.654 -2.147137,-2.37342 -2.205395,-2.095038 -0.0488,0.233183
				0.04049,1.611826 0.198437,3.063651 0.378534,3.479547 0.394304,3.344586 -0.440438,3.76924 -1.743363,0.886893 -1.500089,1.06395
				-5.978572,-4.351249 z M 39.82152,24.055668 c -0.391202,-0.632976 -3.514386,-12.273705 -3.337803,-12.440655 0.426839,-0.403553
				9.122116,-2.6365034 9.355998,-2.4026228 0.135517,0.1355172 0.270764,0.7610419 0.30055,1.3900548 l 0.05416,1.143659 -2.997755,0.815496
				-2.997759,0.815496 0.14388,0.903206 c 0.07914,0.496764 0.237498,0.988841 0.351923,1.093505 0.114424,0.104664 1.386745,-0.148951
				2.82738,-0.563588 1.440635,-0.414638 2.717321,-0.693328 2.837082,-0.619313 0.267838,0.165533 0.844174,2.234208 0.670767,2.407616
				-0.06892,0.06892 -1.2376,0.426063 -2.597077,0.793658 -1.359476,0.367595 -2.581063,0.771619 -2.714635,0.897831 -0.324803,0.306898
				0.298556,2.363046 0.716502,2.363391 0.176059,1.45e-4 1.671823,-0.373998 3.323916,-0.831428 2.497756,-0.691576 3.043137,-0.769625
				3.237254,-0.463285 0.309747,0.488821 0.607742,2.074466 0.424328,2.257878 -0.107265,0.107266 -9.061112,2.726644 -9.361753,2.738709
				-0.02779,0.0011 -0.134421,-0.133708 -0.236953,-0.299608 z m 11.713034,-3.063683 c -0.207132,-0.342026 2.235322,-13.1444678
				2.577134,-13.5083918 0.17422,-0.1854891 0.915731,-0.4081918 1.647804,-0.494895 l 1.331042,-0.1576424 3.415485,5.8952652 c 1.878518,3.242396
				3.415486,5.965411 3.415486,6.051145 0,0.08573 -0.595313,0.305602 -1.322917,0.488596 -1.265097,0.318175 -1.346044,0.305385 -1.852083,-0.292641
				-0.291042,-0.343947 -0.64823,-0.81615 -0.79375,-1.049339 -0.242242,-0.38818 -0.464979,-0.385678 -2.637775,0.02962 l -2.373193,0.453605
				-0.219345,1.109162 c -0.1367,0.691238 -0.38897,1.155485 -0.669518,1.232102 -1.127964,0.308042 -2.404509,0.431425 -2.51837,0.24341 
				z m 6.51279,-6.150501 c 0.116189,-0.106462 -0.269089,-1.074814 -0.856176,-2.151894 -0.587086,-1.07708 -1.071483,-1.898796 -1.076441,-1.826036
				-0.0049,0.07276 -0.169521,1.119498 -0.365699,2.326083 l -0.35669,2.193792 1.221875,-0.174189 c 0.672031,-0.0958 1.31694,-0.261293
				1.433131,-0.367756 z m 8.271725,3.628841 C 66.215051,18.124134 65.637457,7.9059092 65.639892,6.4550397 c 0.0013,-0.7616097 0.09038,-0.8509857
				0.993592,-0.9966105 0.545703,-0.087984 2.364386,-0.1805347 4.041519,-0.2056678 2.594758,-0.038884 3.179257,0.030799 3.920879,0.4674442
				2.557624,1.5058498 2.747994,5.4962834 0.346895,7.2714914 l -0.807312,0.596873 1.597112,2.06454 c 0.878411,1.135497 1.520039,2.189244
				1.425842,2.341659 -0.0942,0.152415 -0.875784,0.277119 -1.73686,0.277119 h -1.56559 l -1.466834,-1.948865 c -1.306513,-1.735862
				-1.47348,-2.102725 -1.527649,-3.356594 l -0.06081,-1.407728 0.919676,-0.194091 c 0.94094,-0.198579 1.858449,-1.054164 1.858449,-1.733019
				0,-0.2087743 -0.307856,-0.6874457 -0.684125,-1.0637139 -0.625563,-0.6255629 -0.819865,-0.6718157 -2.269861,-0.5403347 -0.872154,0.079084
				-1.625878,0.1839928 -1.674939,0.2331299 -0.04906,0.049137 0.04021,2.3319317 0.19839,5.0728767 0.244742,4.24107 0.231584,5.018986
				-0.08833,5.22147 -0.573347,0.362887 -2.625432,0.299477 -2.740859,-0.08469 z M 43.151712,45.54102 c 15.717211,-2.094309 30.558705,-6.891686
				41.675132,-13.471112 3.872516,-2.292006 9.389454,-6.507966 9.389454,-7.175279 0,-0.196046 -3.879614,-1.110232 -6.879167,-1.620994
				-2.635726,-0.448811 -14.228319,-0.614332 -18.299211,-0.261279 -20.3226,1.762501 -41.299125,8.821994 -53.535168,18.016862 -2.856657,2.146658
				-3.541555,2.836035 -3.012578,3.032276 1.710053,0.634408 7.444367,1.730412 10.950078,2.092897 0.582084,0.06019 4.332553,0.06975
				8.334376,0.02127 5.436338,-0.06588 8.312965,-0.226338 11.377084,-0.634629 z"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const BlankPartitionPlain: Component<IconProps> = (props) => {
  const dim = 1024;
  const icon = new DynamicIcon(props, dim, dim, 'Blank Partition');
  const color = '#A0A0A080'; // light gray semi-transparent
  const selectedcolor = '#404040'; // dark gray opaque
  const selectedcontrast = '#E0E0E0'; // very light gray opaque
  const thickness = 24; // should be even number
  const innerCorner = Math.round(0.3 * dim);
  const margin = 56;
  const bgMargin = margin - thickness;
  const bgThickness = 3 * thickness;

  return (
    // *INDENT-OFF*
    <svg
      viewBox={'0 0 ' + dim + ' ' + dim}
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <Show when={icon.sigGetSelected() === 'true'}>
        <line
          x1={bgMargin}
          y1={bgMargin + bgThickness / 2}
          x2={innerCorner}
          y2={bgMargin + bgThickness / 2}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={bgMargin + bgThickness / 2}
          y1={bgMargin + bgThickness}
          x2={bgMargin + bgThickness / 2}
          y2={innerCorner}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={dim - bgMargin}
          y1={bgMargin + bgThickness / 2}
          x2={dim - innerCorner}
          y2={bgMargin + bgThickness / 2}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={dim - (bgMargin + bgThickness / 2)}
          y1={bgMargin + bgThickness}
          x2={dim - (bgMargin + bgThickness / 2)}
          y2={innerCorner}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={bgMargin}
          y1={dim - (bgMargin + bgThickness / 2)}
          x2={innerCorner}
          y2={dim - (bgMargin + bgThickness / 2)}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={bgMargin + bgThickness / 2}
          y1={dim - (bgMargin + bgThickness)}
          x2={bgMargin + bgThickness / 2}
          y2={dim - innerCorner}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={dim - bgMargin}
          y1={dim - (bgMargin + bgThickness / 2)}
          x2={dim - innerCorner}
          y2={dim - (bgMargin + bgThickness / 2)}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
        <line
          x1={dim - (bgMargin + bgThickness / 2)}
          y1={dim - (bgMargin + bgThickness)}
          x2={dim - (bgMargin + bgThickness / 2)}
          y2={dim - innerCorner}
          stroke-width={bgThickness}
          stroke={selectedcontrast}
        />
      </Show>
      <line
        x1={margin}
        y1={margin + thickness / 2}
        x2={innerCorner}
        y2={margin + thickness / 2}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={margin + thickness / 2}
        y1={margin + thickness}
        x2={margin + thickness / 2}
        y2={innerCorner}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={dim - margin}
        y1={margin + thickness / 2}
        x2={dim - innerCorner}
        y2={margin + thickness / 2}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={dim - (margin + thickness / 2)}
        y1={margin + thickness}
        x2={dim - (margin + thickness / 2)}
        y2={innerCorner}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={margin}
        y1={dim - (margin + thickness / 2)}
        x2={innerCorner}
        y2={dim - (margin + thickness / 2)}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={margin + thickness / 2}
        y1={dim - (margin + thickness)}
        x2={margin + thickness / 2}
        y2={dim - innerCorner}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={dim - margin}
        y1={dim - (margin + thickness / 2)}
        x2={dim - innerCorner}
        y2={dim - (margin + thickness / 2)}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
      <line
        x1={dim - (margin + thickness / 2)}
        y1={dim - (margin + thickness)}
        x2={dim - (margin + thickness / 2)}
        y2={dim - innerCorner}
        stroke-width={thickness}
        stroke={icon.sigGetSelected() === 'true' ? selectedcolor : color}
      />
    </svg>
    // *INDENT-ON*
  );
};

export const BackgroundIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Set Background Color');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 512 512"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M384,469.333h-21.333c-11.797,0-21.333,9.557-21.333,21.333S350.869,512,362.667,512H384
				c11.797,0,21.333-9.557,21.333-21.333S395.797,469.333,384,469.333z"
      />
      <path
        d="M149.333,426.667c11.797,0,21.333-9.557,21.333-21.333V384c0-11.776-9.536-21.333-21.333-21.333
				C137.536,362.667,128,372.224,128,384v21.333C128,417.109,137.536,426.667,149.333,426.667z"
      />
      <path
        d="M170.667,469.333h-21.333c-11.797,0-21.333,9.557-21.333,21.333S137.536,512,149.333,512h21.333
				c11.797,0,21.333-9.557,21.333-21.333S182.464,469.333,170.667,469.333z"
      />
      <path
        d="M277.333,469.333H256c-11.797,0-21.333,9.557-21.333,21.333S244.203,512,256,512h21.333
				c11.797,0,21.333-9.557,21.333-21.333S289.131,469.333,277.333,469.333z"
      />
      <path
        d="M362.667,149.333c0-11.776-9.536-21.333-21.333-21.333H320c-11.797,0-21.333,9.557-21.333,21.333
				s9.536,21.333,21.333,21.333h21.333C353.131,170.667,362.667,161.109,362.667,149.333z"
      />
      <path
        d="M490.667,384c-11.797,0-21.333,9.557-21.333,21.333v21.333c0,11.776,9.536,21.333,21.333,21.333S512,438.443,512,426.667
				v-21.333C512,393.557,502.464,384,490.667,384z"
      />
      <path
        d="M490.667,277.333c-11.797,0-21.333,9.557-21.333,21.333V320c0,11.776,9.536,21.333,21.333,21.333S512,331.776,512,320
				v-21.333C512,286.891,502.464,277.333,490.667,277.333z"
      />
      <path
        d="M490.667,170.667c-11.797,0-21.333,9.557-21.333,21.333v21.333c0,11.776,9.536,21.333,21.333,21.333
				S512,225.109,512,213.333V192C512,180.224,502.464,170.667,490.667,170.667z"
      />
      <path
        d="M469.333,149.333c0-11.776-9.536-21.333-21.333-21.333h-21.333c-11.797,0-21.333,9.557-21.333,21.333
				s9.536,21.333,21.333,21.333H448C459.797,170.667,469.333,161.109,469.333,149.333z"
      />
      <path
        d="M490.667,469.333h-21.333c-11.797,0-21.333,9.557-21.333,21.333S457.536,512,469.333,512h21.333
				c11.797,0,21.333-9.557,21.333-21.333S502.464,469.333,490.667,469.333z"
      />
      <path
        d="M277.333,0h-256C9.536,0,0,9.557,0,21.333v256c0,11.776,9.536,21.333,21.333,21.333H128
				c0,11.776,9.536,21.333,21.333,21.333c11.797,0,21.333-9.557,21.333-21.333v-21.333V192v-21.333h42.667h21.333h42.667
				c11.797,0,21.333-9.557,21.333-21.333v-128C298.667,9.557,289.131,0,277.333,0z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const BrightnessContrastIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Adjust Brightness & Contrast');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 32 32"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <rect x="15" y="2" width="2" height="3" />
      <rect x="27" y="15" width="3" height="2" />
      <rect x="15" y="27" width="2" height="3" />
      <rect x="2" y="15" width="3" height="2" />
      <rect
        x="6.22"
        y="5.73"
        width="2"
        height="3"
        transform="translate(-3 7.23) rotate(-45)"
      />
      <rect
        x="23.27"
        y="6.23"
        width="3"
        height="2"
        transform="translate(2.14 19.63) rotate(-45)"
      />
      <rect
        x="23.77"
        y="23.27"
        width="2"
        height="3"
        transform="translate(-10.26 24.77) rotate(-45)"
      />
      <polygon points="5.47 25.13 7.59 23 9 24.42 6.88 26.54 5.47 25.13" />
      <path d="M16,8a8,8,0,1,0,8,8A8,8,0,0,0,16,8Zm0,14a6,6,0,0,1,0-12Z" />
      <rect fill="none" width="32" height="32" />
    </svg>
    // *INDENT-ON*
  );
};
export const CropIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Image');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M6 3V14.8C6 15.9201 6 16.4802 6.21799 16.908C6.40973 17.2843 6.71569 17.5903 7.09202 17.782C7.51984 18 8.0799 18 9.2 18H15M21
				18H18M18 21V9.2C18 8.0799 18 7.51984 17.782 7.09202C17.5903 6.71569 17.2843 6.40973 16.908 6.21799C16.4802 6 15.9201 6 14.8 6H9M3 6H6"
        fill="none"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const EffectIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Apply Effect');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 512 512"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M166.646,166.653c34.995-34.961,91.91-34.961,126.861,0l95.26,95.266l64.184-85.884l-97.203-28.632L303.995,0
				L193.47,64.711L51.816,51.791l-0.017-0.011v0.011h-0.032l0.032,0.032l12.894,141.66l-64.69,110.51L147.39,355.74l28.648,97.208
				l85.884-64.19l-95.266-95.243C131.679,258.552,131.679,201.637,166.646,166.653z"
      />
      <path
        d="M495.605,416.423L272.872,193.71c-21.845-21.867-57.301-21.867-79.163,0
				c-21.856,21.856-21.856,57.296,0,79.174l222.722,222.723c21.89,21.857,57.301,21.857,79.19,0
				C517.451,473.718,517.467,438.3,495.605,416.423z M253.784,291.006l-39.098-39.109c-5.162-5.201-7.687-11.824-7.687-18.621
				c0-6.776,2.514-13.399,7.671-18.599c5.206-5.158,11.851-7.698,18.631-7.698c6.776-0.022,13.421,2.54,18.611,7.719l39.093,39.098
				L253.784,291.006z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const GalleryIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Choose Image from Gallery');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M18.5116 10.0767C18.5116 10.8153 17.8869 11.4142 17.1163 11.4142C16.3457 11.4142 15.7209 10.8153 15.7209 10.0767C15.7209 9.33801 
				16.3457 8.7392 17.1163 8.7392C17.8869 8.7392 18.5116 9.33801 18.5116 10.0767Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0363 5.53205C16.9766 5.39548 15.6225 5.39549 13.9129 5.39551H10.0871C8.37751 5.39549 
				7.02343 5.39548 5.9637 5.53205C4.87308 5.6726 3.99033 5.96873 3.29418 6.63601C2.59803 7.30329 2.28908 8.14942 2.14245 9.19481C1.99997 
				10.2106 1.99999 11.5085 2 13.1472V13.2478C1.99999 14.8864 1.99997 16.1843 2.14245 17.2001C2.28908 18.2455 2.59803 19.0916 3.29418
				19.7589C3.99033 20.4262 4.87307 20.7223 5.9637 20.8629C7.02344 20.9994 8.37751 20.9994 10.0871 20.9994H13.9129C15.6225 20.9994 16.9766
				20.9994 18.0363 20.8629C19.1269 20.7223 20.0097 20.4262 20.7058 19.7589C21.402 19.0916 21.7109 18.2455 21.8575 17.2001C22 16.1843 22
				14.8864 22 13.2478V13.1472C22 11.5085 22 10.2106 21.8575 9.19481C21.7109 8.14942 21.402 7.30329 20.7058 6.63601C20.0097 5.96873 19.1269
				5.6726 18.0363 5.53205ZM6.14963 6.8576C5.21373 6.97821 4.67452 7.2044 4.28084 7.58175C3.88716 7.95911 3.65119 8.47595 3.52536 
				9.37303C3.42443 10.0926 3.40184 10.9919 3.3968 12.1682L3.86764 11.7733C4.99175 10.8305 6.68596 10.8846 7.74215 11.897L11.7326 
				15.7219C12.1321 16.1049 12.7611 16.1571 13.2234 15.8457L13.5008 15.6589C14.8313 14.7626 16.6314 14.8664 17.8402 15.9092L20.2479
				17.9862C20.3463 17.7222 20.4206 17.4071 20.4746 17.0219C20.6032 16.1056 20.6047 14.8977 20.6047 13.1975C20.6047 11.4972 20.6032 
				10.2893 20.4746 9.37303C20.3488 8.47595 20.1128 7.95911 19.7192 7.58175C19.3255 7.2044 18.7863 6.97821 17.8504 6.8576C16.8944 6.73441 
				15.6343 6.73298 13.8605 6.73298H10.1395C8.36575 6.73298 7.10559 6.73441 6.14963 6.8576Z"
      />
      <g opacity="0.5">
        <path
          d="M17.0866 2.61039C16.2268 2.49997 15.1321 2.49998 13.7675 2.5H10.6778C9.31314 2.49998 8.21844 2.49997 7.35863 2.61039C6.46826 
					2.72473 5.72591 2.96835 5.13712 3.53075C4.79755 3.8551 4.56886 4.22833 4.41309 4.64928C4.91729 4.41928 5.48734 4.28374 6.12735
					4.20084C7.21173 4.06037 8.5973 4.06038 10.3466 4.06039H14.2615C16.0108 4.06038 17.3963 4.06037 18.4807 4.20084C19.0397 4.27325 
					19.5453 4.38581 20.0003 4.56638C19.8457 4.17917 19.6253 3.83365 19.3081 3.53075C18.7193 2.96835 17.977 2.72473 17.0866 2.61039Z"
        />
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const GrayscaleSepiaIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Apply Grayscale/Sepia');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-4 0 32 32"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M0 25.344v-18.688h24v18.688h-24zM11.969 21.781v1.969h2v-1.969h2.031v-1.906h-2.031v-1.969h2.031v-1.906h-2.031v-1.938h2.031v-1.906h
				-2.031v-1.969h2.031v-1.938h-2.031v1.938h-2v-1.938h-2.031v1.938h-1.938v-1.938h-6.406v15.5h8.344v-1.969h2.031zM11.969
				10.188v1.969h-2.031v-1.969h2.031zM8 14.063v-1.906h1.938v1.906h-1.938zM13.969 14.063h-2v-1.906h2v1.906zM9.938
				14.063h2.031v1.938h-2.031v-1.938zM8 17.906v-1.906h1.938v1.906h-1.938zM13.969 17.906h-2v-1.906h2v1.906zM9.938
				19.875v-1.969h2.031v1.969h-2.031zM8 21.781v-1.906h1.938v1.906h-1.938zM11.969 19.875h2v1.906h-2v-1.906z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const HueSaturationIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Adjust Hue & Saturation');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M19.988,8.756a7.991,7.991,0,0,0-15.976,0A7.991,7.991,0,0,0,12,22.411,7.992,7.992,0,0,0,19.988,8.756Zm-3.315,4a8,8,0,0,0-2.354-3.713,
				5.938,5.938,0,0,1,3.625.737A5.963,5.963,0,0,1,16.673,12.754ZM12,20.188a6.013,6.013,0,0,1-2.807-3.706,7.864,7.864,0,0,0,5.614,0A6.013,6.013,
				0,0,1,12,20.188ZM6.056,9.778a5.934,5.934,0,0,1,3.625-.737,8,8,0,0,0-2.354,3.713A5.963,5.963,0,0,1,6.056,9.778Zm8.888,4.444a5.959,5.959,0,0,1-5.888,0A6,6,0,0,1,12,9.812,6,6,
				0,0,1,14.944,14.222ZM12,3a6,6,0,0,1,5.807,4.518A7.9,7.9,0,0,0,12,7.589a7.9,7.9,0,0,0-5.807-.071A6,6,0,0,1,12,3ZM9,21a5.993,5.993,0,0,1-4.673-9.754,
				8.018,8.018,0,0,0,2.685,4,7.971,7.971,0,0,0,2.669,5.715A6.133,6.133,0,0,1,9,21Zm6,0a6.133,6.133,0,0,
				1-.681-.041,7.971,7.971,0,0,0,2.669-5.715,8.018,8.018,0,0,0,2.685-4A5.993,5.993,0,0,1,15,21Z"
        fill="#000000"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const LinkIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Linked Partitions');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M14 12C14 14.7614 11.7614 17 9 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H7.5M10 12C10 9.23858 12.2386 7 15 
				7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17H16.5"
        fill="none"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const MoveIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Position');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M12 21V12M12 21L15 18M12 21L9 18M12 12V3M12 12H3M12 12H21M12 3L9 6M12 3L15 6M3 12L6 15M3 12L6 9M21 12L18 9M21 12L18 15"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const PosterizeIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Posterize Image');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M512.733035 0.470642C230.09804 0.470642 0.941284 229.627398 0.941284 512.262393c0 188.927292 102.404165 353.93515 254.717188
				442.603238 15.385406-43.324038 40.441877-78.622175 71.675002-105.802782 24.723273-21.724494 53.911397-38.859188
				86.589767-50.596079-59.521614-51.858066-61.212593-65.952331-40.733426-63.982299a195.341349 195.341349 0 0
				1-47.334906-53.249166c-262.359912-19.708647-45.798031-534.844871 187.115529-412.407191 1.845083-0.970438 3.690165-1.903392 
				5.535248-2.790532 97.193785-47.001708 189.926885 15.114683 246.087367 104.486651 77.993264 122.866672 86.86049 297.362336-59.288376
				310.481998-13.423705 22.29926-30.679183 40.87087-50.500284 55.73982 10.212511 3.386122 5.96424 20.04601-28.484243 62.041422 32.341007
				11.728561 61.250077 28.750801 85.765102 50.296201 29.562971 25.735362 53.632344 58.713609 69.167689 98.913918 145.973936-90.154983
				243.276011-251.5768 243.27601-435.731034 0-282.63916-229.156756-511.795916-511.795916-511.795916z"
        fill="#979797"
      />
      <path
        d="M295.37981 561.055039c-0.054145-17.068055-0.516457-33.582169-0.591426-49.367412-1.103718 17.347108-0.887139 33.786252 0.591426
				49.367412z"
        fill="#979797"
      />
      <path
        d="M327.337639 849.058684c-31.233125 27.180607-56.293761 62.478744-71.675002 105.802782l0.00833 0.004165c15.027219-41.683039
				39.575563-77.601757 71.666672-105.806947zM712.085252 849.079509c30.316831 26.64749 53.886407 60.192174 69.076059
				98.972228l0.09163-0.05831c-15.535345-40.200309-39.608883-73.178557-69.167689-98.913918zM544.378492 779.666086c5.26036 0.478972
				89.176214-49.967168 110.425901-42.9242 19.821101-14.864785 37.07658-33.436395 50.500284-55.73982a199.460506 199.460506 0 0 1-15.477035
				0.791345c34.82333-50.41282 52.532791-112.629171 42.028732-194.091857l-216.520231 1.062068h-2.598943v-0.012495l-215.695567
				-1.049573a372.090263 372.090263 0 0 0-2.253249 23.981908c0.074969 15.785244 0.537281 32.299357 0.591426 49.367412 4.469015 47.164142
				20.145969 86.668901 43.690555 120.742537a210.231123 210.231123 0 0 1-13.211291-0.570601 195.349679 195.349679 0 0 0 47.334906
				53.249166c31.720426 3.044594 116.640039 44.631839 122.308566 44.132043 19.829431 2.482323 28.921565 1.657659 48.875946 1.062067z"
        fill="#FFFFFF"
      />
      <path
        d="M712.085252 849.079509c-24.515025-21.5454-53.424095-38.56764-85.765102-50.296201l-0.00833 0.012494-212.276125-0.237403
				-0.108289-0.095794c-32.674205 11.736891-61.866493 28.87575-86.589767 50.596079-32.095274 28.201025-56.639453 64.123908-71.670837
				105.806947 75.548426 43.977939 163.358529 69.184349 257.066233 69.184349 98.443277 0 190.380867-27.826177
				268.424111-76.002408-15.185488-38.775889-38.755064-72.316408-69.071894-98.968063z"
        fill="#A9A9A9"
      />
      <path
        d="M626.31182 798.795802l0.00833-0.012494c34.448483-41.995412 38.696754-58.651135 28.484243-62.041422-21.249687-7.042968-105.165541
				43.403173-110.425901 42.9242-19.954381 0.595591-29.046514 1.420255-48.880111-1.057902-5.664362
				0.499797-90.58814-41.083283-122.308566-44.132043-20.479167-1.970032-18.784023 12.124233 40.733426 63.982299l0.108289 0.095794 212.28029
				0.241568z"
        fill="#DCDCDC"
      />
      <path
        d="M764.593053 370.520068c-56.160482-89.371968-148.893582-151.488359-246.087367-104.486651 96.531555-46.639356 189.497893 15.331262
				246.087367 104.486651zM295.37981 561.055039c-1.4744-15.58116-1.695144-32.020304-0.591426-49.367412 0.495632-7.821817 1.195347-15.781079
				2.253249-23.981908l215.695567 1.049573V268.823949h0.237403c-232.913561-122.43768-449.475441 392.694379-187.115529 412.407191 4.277426
				0.320703 8.679802 0.516457 13.211291 0.570601-23.544586-34.0778-39.221541-73.582559-43.690555-120.746702z"
        fill="#484848"
      />
      <path
        d="M512.733035 488.759457v0.012495h2.598943l216.520231-1.062068c10.504059 81.462686-7.201236 143.683202-42.028732 194.091857a201.547157
				201.547157 0 0 0 15.477035-0.791345c146.15303-13.119662 137.28164-187.615326
				59.288376-310.481998-56.589474-89.159554-149.555812-151.126007-246.087367-104.486651-1.840918 0.887139-3.690165 1.820093-5.535248
				2.790532h-0.237403v219.927178z"
        fill="#151515"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const RotateIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Rotate');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M4.06189 13C4.02104 12.6724 4 12.3387 4 12C4 7.58172 7.58172 4 12 4C14.5006 4 16.7332 5.14727 18.2002 6.94416M19.9381 11C19.979 
				11.3276 20 11.6613 20 12C20 16.4183 16.4183 20 12 20C9.61061 20 7.46589 18.9525 6 17.2916M9 17H6V17.2916M18.2002 4V6.94416M18.2002
				6.94416V6.99993L15.2002 7M6 20V17.2916"
        fill="none"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const TurnIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Toggle Orientation');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 211.66666 211.66667"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        style="fill:#000000;stroke-width:0.264583"
        d="m 52.666179,195.76112 c 
			-0.824175,-0.27308 -1.895737,-0.74501 -2.38125,-1.04875 -0.485511,-0.30374 
			-8.0977,-7.79098 -16.915977,-16.63831 C 15.0791,159.72394 16.072826,160.95972 
			16.067645,156.55848 c -0.0052,-4.44423 -0.796513,-3.46595 17.77848,-21.98009 
			17.245759,-17.18926 16.895522,-16.88682 20.165746,-17.41348 4.386813,-0.70647 
			8.611671,1.59056 10.556211,5.73935 0.613145,1.30819 0.709099,1.85304 
			0.709099,4.02645 0,4.10292 -0.169172,4.35576 -8.540951,12.7648 l 
			-7.062589,7.09402 34.260102,-0.10741 c 37.118757,-0.11638 35.331377,-0.0478 
			40.167577,-1.54093 8.68876,-2.68254 16.30801,-10.43805 19.08383,-19.42511 
			1.33757,-4.33054 1.33254,-4.17816 1.33368,-40.391098 5.3e-4,-18.590286 
			-0.0882,-33.800719 -0.19738,-33.800965 -0.10914,-2.44e-4 -3.23453,3.09337 
			-6.94531,6.874698 -6.18235,6.299885 -6.90184,6.947352 -8.59896,7.738186 
			-1.66191,0.774436 -2.09658,0.862748 -4.23333,0.860116 -2.17658,-0.0027 
			-2.53647,-0.08044 -4.18716,-0.904732 -2.20213,-1.099656 -4.16819,-3.190158 
			-4.98028,-5.295527 -0.79299,-2.055855 -0.77927,-5.134311 0.0325,-7.287922 
			0.5695,-1.510895 1.38526,-2.376374 16.90521,-17.935379 12.76561,-12.797745 
			16.59612,-16.490075 17.63896,-17.002671 1.90328,-0.935539 4.22634,-1.2971 
			5.98527,-0.931548 3.44094,0.715121 3.14553,0.459543 20.14633,17.42997 
			14.9587,14.931983 15.87547,15.901327 16.80104,17.764449 0.91168,1.835179 
			0.97898,2.135296 0.97898,4.365625 0,2.079519 -0.10016,2.611818 -0.76045,4.041306 
			-0.93176,2.017184 -2.99645,4.050811 -5.12583,5.048711 -1.29969,0.609081 
			-1.86994,0.713287 -3.9033,0.713287 -3.96078,0 -4.29519,-0.225782 
			-12.6014,-8.507899 -4.19051,-4.178353 -7.11847,-6.906088 -7.2261,-6.731942 
			-0.0995,0.161025 -0.14389,15.978833 -0.0986,35.150682 0.0765,32.396613 
			0.0493,35.044733 -0.38605,37.503743 -1.71588,9.69248 -5.15402,17.2297 
			-11.13287,24.40593 -6.42597,7.71291 -16.05417,13.76136 -25.56917,16.06261 
			-6.62859,1.60316 -5.14837,1.55076 -42.667583,1.5105 -23.836876,-0.0256 
			-34.329688,0.0481 -34.329688,0.24114 0,0.1529 3.082057,3.33653 6.849018,7.07474 
			8.203938,8.14133 8.364524,8.38357 8.364524,12.61758 0,2.34753 -0.08162,2.81202 
			-0.723895,4.11983 -2.17397,4.42665 -7.446886,6.78279 -11.887107,5.31162 z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const SharpenBlurIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Sharpen or Blur Image');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 
				22C6.47715 22 2 17.5228 2 12ZM13 6C13 5.44772 13.4477 5 14 5H16C16.5523 5 17 5.44772 17 6C17 6.55228 16.5523 7 16 7H14C13.4477 7 13 
				6.55228 13 6ZM14 9C13.4477 9 13 9.44772 13 10C13 10.5523 13.4477 11 14 11H19C19.5523 11 20 10.5523 20 10C20 9.44772 19.5523 9 19 
				9H14ZM13 14C13 13.4477 13.4477 13 14 13H19C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15H14C13.4477 15 13 14.5523 13 14ZM14
				17C13.4477 17 13 17.4477 13 18C13 18.5523 13.4477 19 14 19H16C16.5523 19 17 18.5523 17 18C17 17.4477 16.5523 17 16 17H14Z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const StickerIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Apply Sticker');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 12.6477 21.7004 13.2503 21.2424 13.7083L13.7083 
				21.2424C13.2503 21.7004 12.6477 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12 17C10.8846 17 9.85038 16.6303 9 16"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <ellipse cx="15" cy="10.5" rx="1" ry="1.5" fill="#000000" />
      <ellipse cx="9" cy="10.5" rx="1" ry="1.5" fill="#000000" />
      <path
        d="M12 22C12 19.2071 12 17.8107 12.3928 16.688C13.0964 14.6773 14.6773 13.0964 16.688 12.3928C17.8107 12 19.2071 12 22 12"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const UndoIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Undo Action');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-0.5 0 25 25"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="m 10,21.4199 h 5 c 1.85652,0 3.63698,-0.7375 4.94974,-2.0503 C 21.26249,18.0569 22,16.2764 22,14.4199 22,12.5634 21.26249,10.783
					19.94974,9.47021 18.63698,8.15746 16.85652,7.41992 15,7.41992 H 2"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m 6,11.4199 -4,-3.99998 4,-4"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const RedoIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Redo Action');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-0.5 0 25 25"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M14 21.4199H9C7.14348 21.4199 5.36302 20.6824 4.05026 19.3696C2.73751 18.0569 2 16.2764 2 14.4199C2 12.5634 2.73751 10.783 4.05026
				9.47021C5.36302 8.15746 7.14348 7.41992 9 7.41992H22"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 11.4199L22 7.41992L18 3.41992"
        fill="none"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const UnlinkIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Unlinked Partitions');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 512 512"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M200.66,352H144a96,96,0,0,1,0-192h55.41"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="48"
      />
      <path
        d="M312.59,160H368a96,96,0,0,1,0,192H311.34"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="48"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const UploadIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Upload Image(s)');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 512 512"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M470.7,277.2c3-11.2,4.7-22.9,4.7-35c0-75.8-61.4-137.1-137.1-137.1c-19.5,0-38,4.1-54.7,11.4
				c-16.8-39-55.6-66.3-100.7-66.3c-60.6,0-109.7,49.1-109.7,109.7c0,4.1,0.8,7.9,1.2,11.9C30.5,192.1,0,236.3,0,287.9
				c0,70.7,57.3,128,128,128h310.9c40.4,0,73.1-32.7,73.1-73.1C512,313.8,495.1,289.1,470.7,277.2z M292.6,251.3v91.4h-73.1v-91.4
				h-54.9l91.4-91.4l91.4,91.4H292.6z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const PlusIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Upload Image(s)');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 48 48"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M30 4H18V18H4V30H18V44H30V30H44V18H30V4Z"
        fill="#FF68EC"
        stroke="#000000"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const VignetteIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Apply Vignette Frame');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 24 24"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        d="M18 21L18 3"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 21L6 3"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 6L3 6"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 18L21 18"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const WaitIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Loading ...');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 32 32"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        fill="#FBFF53"
        d="M4.601,27.22C1.758,24.332,0,20.373,0,16S1.758,7.668,4.601,4.78
				c0.354,0.474,0.841,0.837,1.408,1.038C6.007,5.879,6,5.938,6,6v4c0,3.3,2.7,6,6,6c-3.3,0-6,2.7-6,6v4
				c0,0.062,0.007,0.121,0.009,0.182C5.442,26.383,4.955,26.746,4.601,27.22z M25.991,5.818C25.993,5.879,26,5.938,26,6v4
				c0,3.3-2.7,6-6,6c3.3,0,6,2.7,6,6v4c0,0.062-0.007,0.121-0.009,0.182c0.567,0.201,1.054,0.563,1.408,1.038
				C30.242,24.332,32,20.373,32,16s-1.758-8.332-4.601-11.22C27.045,5.254,26.558,5.617,25.991,5.818z"
      />
      <path
        fill="#FFAAF0"
        d="M26,10V6c0-0.062-0.007-0.121-0.009-0.182C25.679,5.928,25.349,6,25,6h-9H7
				C6.651,6,6.321,5.928,6.009,5.818C6.007,5.879,6,5.938,6,6v4c0,3.3,2.7,6,6,6c-3.3,0-6,2.7-6,6v4c0,0.062,0.007,0.121,0.009,0.182
				C6.321,26.072,6.651,26,7,26H6.754l7.125-7.125C14.462,18.292,15.231,18,16,18s1.538,0.292,2.121,0.875L25.246,26H25
				c0.349,0,0.679,0.072,0.991,0.182C25.993,26.121,26,26.062,26,26v-4c0-3.3-2.7-6-6-6C23.3,16,26,13.3,26,10z"
      />
      <path
        fill="#FF68EC"
        d="M18.121,18.875C17.538,18.292,16.769,18,16,18s-1.538,0.292-2.121,0.875L6.754,26H7h9h9h0.246
				L18.121,18.875z"
      />
      <path
        fill="#676EF4"
        d="M27.399,4.78c-0.354,0.474-0.841,0.837-1.408,1.038C25.679,5.928,25.349,6,25,6h-9V0
				c3.376,0,6.503,1.051,9.084,2.836C25.916,3.411,26.691,4.061,27.399,4.78z M25.991,26.182C25.679,26.072,25.349,26,25,26h-9v6
				c3.376,0,6.503-1.051,9.084-2.836c0.832-0.575,1.607-1.225,2.315-1.944C27.045,26.746,26.558,26.383,25.991,26.182z"
      />
      <path
        fill="#85E0F6"
        d="M16,0v6H7C6.651,6,6.321,5.928,6.009,5.818C5.442,5.617,4.955,5.254,4.601,4.78
				c0.707-0.719,1.483-1.369,2.315-1.944C9.497,1.051,12.624,0,16,0z M7,26c-0.349,0-0.679,0.072-0.991,0.182
				c-0.567,0.201-1.054,0.563-1.408,1.038c0.707,0.719,1.483,1.369,2.315,1.944C9.497,30.949,12.624,32,16,32v-6H7z"
      />
    </svg>
    // *INDENT-ON*
  );
};

export const CropCircleIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Circle');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <circle cx="0" cy="0" r="512" fill="white" />
    </svg>
    // *INDENT-ON*
  );
};
export const CropOvalIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Oval');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <ellipse cx="0" cy="0" rx="384" ry="512" fill="white" />
    </svg>
    // *INDENT-ON*
  );
};
export const CropSquareIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Square');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <rect x="-384" y="-384" width="768" height="768" fill="white" />
    </svg>
    // *INDENT-ON*
  );
};
export const CropSplashIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Splash');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        style="fill:#ffffff;stroke-width:7.447"
        transform="translate(-512,-512)"
        d="m 840.32948,990.88121 c -59.128,35.76809 -149.77718,-147.05918 -219.86852,-149.32852 -65.39046,-2.11714 48.35552,159.48891 -20.31548,168.13781 
				-68.67099,8.6489 -28.77046,-178.92658 -96.9178,-175.60961 -68.14733,3.31697 -18.05795,183.03961 -84.32186,176.26601 C 352.6419,1003.5733 
				438.06759,853.02618 379.43944,823.5474 320.81128,794.06863 231.41414,1008.3053 173.91436,976.97899 116.41457,945.65263 347.84717,766.34921 
				296.91013,725.5602 245.97309,684.77118 132.90235,881.94119 91.720938,828.38173 50.539518,774.82226 243.96276,715.92506 219.88551,651.32214 
				195.80826,586.71923 78.213035,695.22531 58.335735,633.05498 38.458435,570.88465 175.86401,605.99197 179.27794,536.77369 182.69187,467.55542 
				19.666819,480.80485 32.172569,416.96183 44.678319,353.1188 187.39205,436.79893 217.12785,376.24546 246.86365,315.69199 36.766989,243.93165 
				76.633769,189.59307 116.50055,135.25449 231.08004,344.96443 284.36325,300.5097 337.64645,256.05496 140.99879,98.932567 195.43804,61.440807 
				249.87729,23.949047 306.72337,256.23689 370.16702,231.7306 433.61067,207.22431 331.57571,48.924129 399.44137,41.018169 467.30705,33.112219 
				462.645,166.99579 530.38689,162.64954 598.12878,158.30329 601.35932,22.520202 669.75962,34.834532 738.15993,47.148852 588.37869,202.00029 
				654.17427,221.15212 719.96985,240.30395 707.37569,133.1008 755.01243,118.11513 1083.0089,14.933306 693.47857,255.09254 744.32881,302.52704 
				c 50.85022,47.43449 172.76043,-163.99721 206.23576,-103.7229 33.47533,60.27432 -162.48408,99.48699 -129.97893,160.10905 32.50515,60.62206 
				125.29049,-44.20912 142.87968,18.37527 17.58919,62.5844 -112.55124,85.66672 -106.2771,150.87498 6.27413,65.20826 161.15098,14.89652 
				149.93778,83.07259 -11.21312,68.17607 -127.97396,-20.52844 -134.80318,32.90588 -8.70289,68.09463 115.05564,111.53389 78.66529,165.64267 
				-36.39035,54.10878 -149.32769,-112.92818 -202.81716,-68.61731 -53.48947,44.31087 151.28653,213.94585 92.15853,249.71394 z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const CropStarIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Star');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <path
        style="fill:#ffffff;stroke-width:7.447"
        transform="translate(-512,-512)"
        d="M 827.14157,981.21833 511.63491,814.87793 195.74211,980.48388 256.4443,629.0172 1.3274419,379.76034 354.35013,328.88239 512.57204,9.2272298 
				670.04986,329.2496 1022.9532,380.9487 767.2572,629.61136 Z"
      />
    </svg>
    // *INDENT-ON*
  );
};
export const CropBackSquareIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Back Square');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <g transform="rotate(-12)">
        <rect x="-430" y="-430" width="850" height="850" fill="white" />
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const CropForwardSquareIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 64, 64, 'Crop Forward Square');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="-512 -512 1024 1024"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <g transform="rotate(12)">
        <rect x="-430" y="-430" width="850" height="850" fill="white" />
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const TabWRSRWigglesIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 1024, 1024, 'Tab WRSR Wiggles');
  const viewDim = 56.7;
  return (
    // *INDENT-OFF*
    <svg
      viewBox={'0 0 ' + viewDim + ' ' + viewDim}
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <title>{icon.sigGetTitle()}</title>
      <defs>
        <clipPath id="wigglesclippath">
          <rect style="fill:none;" x="0" y="0" width="56.7" height="56.7" />
        </clipPath>
      </defs>
      <g transform={'rotate(90 ' + viewDim / 2 + ' ' + viewDim / 2 + ')'}>
        <g style="clip-path:url(#wigglesclippath);">
          <path
            style="fill:#676ff4;"
            d="M42.5,43.5c0-.1-.1-.2-.1-.3s0-.2,0-.3c0,0,0-.2,0-.2,0,0,0-.1,0-.2,0,0,0,0,0-.1,0,0,0,0,0-.1,0,0,0-.1,0-.2,0,0,0-.1.1-.2,0,0,0,0,0-.1,0,0,0,0,0,0,0,0,.2-.4.4-.4s.5.2.6.3c0,0,0,0,0,0,.2.1.3.2.5.4,0,0,0,0,0,0,0,0,.2.1.2.2,0,0,.2.1.3.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.1,0,0,0,0,0,.1.1,0,0,.1.1.2.2,0,0,0,0,0,0,0,0,.2.2.2.3,0,0,.1.2.2.3,0,0,0,0,0,0,.1,0,.1.1.1.2h0c0,0,.1.2.1.2,0,0,0,.2,0,.2,0,0,0,0,0,.1,0,.2,0,.4.1.5,0,0,0,.2,0,.3,0,.1,0,.3,0,.4v.5c0,0,0,.2,0,.3,0,0,0,.2,0,.2,0,0,0,0,0,.1,0,0,0,.1,0,.2,0,0,0,.2,0,.3,0,0,0,.1,0,.2,0,0,0,.1,0,.2,0,0,0,.1,0,.2,0,0-.1.2-.2.3,0,0-.1.1-.2.2,0,0,0,.1-.1.2,0,0,0,0-.2.1,0,0-.1,0-.1.1,0,0-.2.2-.3.3-.1,0-.3.2-.4.2,0,0-.1,0-.2,0,0,0,0,0-.1,0-.2,0-.3.1-.6.2,0,0-.1,0-.2,0,0,0-.2,0-.3,0-.1,0-.2,0-.3,0-.2,0-.4,0-.7,0-.1,0-.2,0-.4,0,0,0,0,0,0,0-.1,0-.3,0-.4,0h0s-.1,0-.2,0c0,0-.2,0-.1-.1,0-.1,0-.2.1-.3,0,0,0,0,0-.1,0,0,0-.2,0-.2,0-.1,0-.2,0-.3,0-.1,0-.3,0-.5,0,0,0,0,0-.2,0-.2,0-.3.1-.5v-.2c0,0,0,0,.1,0,0,0,.1-.1.3-.1,0,0,.2,0,.3,0,.1,0,.2,0,.3,0,0,0,.2,0,.2,0,0,0,.1,0,.2-.1,0,0,0,0,.1-.1,0,0,.1-.2.2-.3,0-.1,0-.3,0-.4s0-.3,0-.4c0,0,0-.1,0-.2,0,0,0-.2,0-.2,0,0,0-.2-.1-.2h0c-.1-.3-.2-.6-.4-.7,0,0,0,0-.1-.1,0,0,0,0,0-.1h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M46.5,9.2c.4.8.6,1.8.5,2.7,0,.6-.3,1.2-.7,1.5-.6.4-1.4.2-2-.2-1.2-.8-2.1-2.5-2.6-3.9-.1-.4-.3-.8-.5-1.2-.3-.5-1-1.1-1.3-.3,0,.2,0,.5-.1.8,0,.7-.3,1.4-.9,1.8-.5.3-1.2.3-1.7,0-.8-.3-1.5-1.2-2.3-1.3-.4,0-.6.3-.5.7,0,.3.3.5.4.8.3.6.3,1.5,0,2.1-.5.9-1.5,1.1-2.3,1-.9,0-1.7-.5-2.5-.9s-1.6-.9-2.4-1.1c-.4-.1-1,0-.9.5,0,.4.4.7.7,1,.7.7,1.3,1.7,1.4,2.7s-.2,2.1-1,2.6c-.8.6-1.9.5-2.9,0s-1.7-1.3-2.4-2.2c-2-2.7-3.2-6.1-2.8-9.4,0-.8.3-1.7.8-2.2,1.5-1.4,4,0,5.4,1.3.8.8,1.5,1.7,2.4,2.4s1,.7,1.5.5c.6-.2.7-1,.8-1.7.1-1.1.2-2.3.3-3.4,0-.5.1-1,.4-1.3.3-.3.8-.4,1.2-.3s.8.4,1.2.7c.7.6,1.3,1.2,1.8,2,.1.2.3.4.4.5s.4.2.6,0c.1,0,.2-.2.2-.3.5-1,.4-2.2.5-3.3s.2-2.3.9-3.1c1-1,2.7-.7,3.8.2s1.8,2.4,2.3,3.9c.5,1.7.9,3.4,1.7,5,.2.4.4.8.6,1.2h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M44.6,28.5c.6.3,1.1.8,1.5,1.3.2.4.4.8.3,1.1-.1.4-.6.6-1.2.6-1,0-2.3-.7-3.2-1.3-.3-.2-.5-.4-.8-.5-.4-.2-1-.3-.8.3,0,.2.2.3.3.5.3.4.5.9.3,1.3-.1.4-.5.6-.9.7-.5.1-1.3-.2-1.8,0-.2,0-.2.4,0,.6.2.1.4.2.6.3.5.2.8.7.9,1.2.1.6-.3,1.1-.8,1.4-.5.2-1.1.3-1.7.3s-1.2,0-1.8.2c-.3,0-.5.3-.3.6.2.2.5.3.8.3.7.1,1.4.5,2,1s.8,1.3.7,1.9c-.1.6-.8,1-1.5,1.1s-1.5-.1-2.3-.4c-2.3-.8-4.5-2.4-5.8-4.4-.3-.5-.6-1-.5-1.5.2-1.4,2.1-1.4,3.5-1.2.8.2,1.6.4,2.4.5.4,0,.8,0,1-.2.2-.3,0-.8-.3-1.2-.5-.7-.9-1.4-1.4-2.1-.2-.3-.4-.6-.4-.9,0-.3.2-.5.5-.6s.6,0,1,0c.6,0,1.2.3,1.9.5.2,0,.3.1.5.2s.3,0,.4-.2c0,0,0-.2,0-.3-.2-.7-.8-1.4-1.2-2.1s-1-1.4-.9-2.1c0-.9,1.1-1.4,2.2-1.2s2.1.8,3,1.4c1,.8,2.1,1.6,3.2,2.2.3.2.6.3.8.5h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M25.9,37.7c.7.7,1.4,1.6,1.6,2.6.2.7.1,1.4-.3,2-.5.7-1.5,1-2.4.8-1.7-.3-3.4-1.7-4.5-2.9-.3-.4-.6-.7-1-1-.6-.4-1.6-.7-1.6.3,0,.3,0,.6.2.9.2.8.2,1.7-.3,2.3-.5.6-1.2.9-2,.9-1,0-2.2-.6-3.1-.3-.5.1-.6.6-.3,1,.2.3.5.5.8.7.6.5.9,1.4.8,2.2-.2,1.2-1.3,1.9-2.3,2.2-1,.3-2.1.3-3.2.2s-2.2-.2-3.2,0c-.5,0-1.1.4-.9,1,.2.5.7.6,1.1.7,1.1.4,2.1,1.2,2.6,2.2s.5,2.4-.1,3.3c-.7,1-2,1.6-3.3,1.5s-2.5-.5-3.5-1.2c-3.3-1.9-6-5.1-6.9-8.8-.2-.9-.3-1.9.1-2.8,1.2-2.3,4.6-2,6.7-1.3,1.2.4,2.4,1.1,3.7,1.4.6.2,1.4.2,1.9-.2.6-.5.4-1.5.3-2.2-.3-1.3-.6-2.6-.9-3.9-.1-.5-.2-1.1,0-1.6.2-.5.8-.8,1.3-.9s1.1,0,1.7.2c1,.3,1.9.7,2.8,1.3.2.1.4.3.7.4s.6,0,.7-.2c0-.1.1-.3.1-.4.2-1.3-.3-2.6-.7-3.8s-.6-2.6,0-3.8c.8-1.6,2.8-2.1,4.5-1.6s3,1.8,4.1,3.1c1.2,1.5,2.4,3.2,3.8,4.5.4.3.7.7,1.1,1h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M19.4,57.2c-.4,0-.8,0-1.2,0-.2,0-.5-.2-.6-.4-.1-.3,0-.6.3-.9.5-.4,1.2-.7,1.9-.8.2,0,.4,0,.5,0,.2,0,.5-.3.2-.5,0,0-.2,0-.3-.1-.3,0-.6-.3-.7-.5,0-.2,0-.5.1-.8.2-.3.6-.5.8-.8,0-.2,0-.3-.3-.3-.1,0-.3,0-.4,0-.3,0-.6,0-.9-.2-.3-.3-.3-.7-.2-1.1.1-.4.3-.7.6-1s.5-.6.7-.9c0-.2.1-.4-.1-.5-.2,0-.3.1-.5.2-.4.2-.8.4-1.3.3s-.9-.3-1-.7c-.2-.4,0-.9.2-1.2s.7-.6,1.1-.8c1.3-.6,2.9-.8,4.3-.4.3.1.7.3.8.5.5.8-.3,1.7-1,2.2-.4.3-.8.5-1.2.8-.2.2-.4.4-.3.6,0,.3.4.4.6.5.5.1.9.3,1.4.4.2,0,.4.1.5.3.1.2.1.4,0,.6s-.2.3-.4.4c-.3.2-.6.4-1,.6,0,0-.2,0-.3.1s-.1.2,0,.2c0,0,0,0,.1.1.4.3.9.4,1.4.5s1,.3,1.2.7c.3.5,0,1.2-.4,1.6s-1.2.5-1.9.6c-.7,0-1.5.1-2.3.3-.2,0-.4,0-.6.1h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M7.5,7.2c0-.1,0-.3,0-.4.2-.7.8-1.3,1.5-1.7s1.5-.4,2.3-.5c1.6,0,3.2.3,4.5,1.1s2.3,2.2,2.5,3.6c.2,1.7-.6,3.4-2,4.5s-3.1,1.7-4.8,2c-.8,0-1.5.1-2.3,0s-1.4-.7-1.6-1.4c-.2-.6,0-1.3.3-1.8s1-.9,1.6-1.1c.6-.2,1.2-.3,1.8-.5s1.5-.8,1.2-1.5c0,0,0,0,0,0,0,0,0,0,0,0-.2-.5-2.5-.3-3-.3-.4,0-.9,0-1.3-.2-.6-.3-.9-1-.9-1.6h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M4.9,11.4c.3,0,.5,0,.8.1.2,0,.4.3.4.6,0,.3-.1.7-.4.9s-.6.3-.9.4c-.4,0-.8.2-1.2.2-.6,0-1.6,0-1.9-.6,0-.2,0-.5.1-.8s.4-.4.7-.5c.4-.2,1-.3,1.4-.3.3,0,.6,0,.9,0h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M4.8,7.6c.3,0,.5,0,.8.1.2,0,.4.3.4.6,0,.3-.1.7-.4.9s-.6.3-.9.4c-.4,0-.8.2-1.2.2-.6,0-1.6,0-1.9-.6,0-.2,0-.5.1-.8s.4-.4.7-.5c.4-.2,1-.3,1.4-.3.3,0,.6,0,.9,0h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M40.3,45.2c.2,0,.3,0,.4.2.1,0,.2.3.1.4,0,.2-.2.3-.4.4s-.4,0-.6,0-.5,0-.7-.1c-.3,0-.9-.3-.9-.7,0-.1,0-.3.2-.4s.3-.1.4-.1c.3,0,.6,0,.8,0,.2,0,.3,0,.5.1Z"
          />
          <path
            style="fill:#676ff4;"
            d="M40.9,43.1c.2,0,.3,0,.4.2.1,0,.2.3.1.4,0,.2-.2.3-.4.4s-.4,0-.6,0-.5,0-.7-.1c-.3,0-.9-.3-.9-.7,0-.1,0-.3.2-.4s.3-.1.4-.1c.3,0,.6,0,.8,0,.2,0,.3,0,.5.1Z"
          />
          <path
            style="fill:#676ff4;"
            d="M35.2,17.5c.4,0,.7-.2,1.1-.1.4,0,.7.3.8.6.2.4,0,.9-.2,1.3s-.7.7-1.1.9c-.5.3-1,.5-1.6.7-.8.2-2.2.6-2.8-.2-.2-.3-.3-.7-.1-1.1s.4-.7.7-.9c.5-.4,1.2-.7,1.9-1,.4-.1.8-.2,1.2-.4Z"
          />
          <path
            style="fill:#676ff4;"
            d="M46,20.7c.1.6.3,1,.2,1.6,0,.5-.4,1-.9,1.2-.6.3-1.4,0-2-.3s-1-1-1.4-1.6c-.4-.7-.8-1.5-1-2.3-.3-1.2-.9-3.1.2-4.1.4-.3,1-.4,1.6-.2s1,.6,1.3,1.1c.6.8,1.1,1.8,1.4,2.7.2.6.4,1.2.5,1.8h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M24.9,23.9c.6.2,1,.3,1.5.7.4.4.6.9.5,1.4-.1.7-.7,1.2-1.3,1.5s-1.4.3-2.1.2c-.8,0-1.7-.2-2.5-.5-1.2-.4-3.1-1.1-3.2-2.5,0-.5.3-1.1.7-1.4s1-.4,1.6-.5c1,0,2.1.1,3,.4.6.1,1.2.4,1.8.6h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M8.1,17.4c.6-.2,1-.3,1.6-.2.5,0,1,.4,1.2.9.3.6.1,1.4-.3,2s-1,1-1.6,1.4c-.7.4-1.5.8-2.3,1-1.2.4-3.1.9-4.1-.2-.3-.4-.4-1-.2-1.6s.6-1,1-1.3c.8-.6,1.8-1.1,2.7-1.4.6-.2,1.2-.4,1.8-.5h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M4.3,28.8c.2-.3.4-.6.7-.8.3-.2.7-.2,1,0,.4.2.6.6.7,1.1s0,.9-.2,1.4c-.2.5-.4,1-.7,1.5-.4.7-1.2,1.8-2.1,1.6-.3,0-.6-.3-.8-.7s-.1-.7,0-1.1c.1-.6.4-1.3.7-1.8.2-.4.4-.7.6-1h0Z"
          />
          <path
            style="fill:#676ff4;"
            d="M36.2,48.9c.6,0,1.2,0,1.7.2.5.2.9.7,1,1.3,0,.7-.3,1.4-.9,1.9s-1.3.8-2,1c-.9.2-1.8.3-2.6.4-1.3,0-3.5,0-4.1-1.4-.2-.5,0-1.2.2-1.7s.9-.8,1.5-1c1-.4,2.1-.6,3.2-.7.7,0,1.3,0,2,0h0Z"
          />
        </g>
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const EyeletSafeZoneIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 566, 566, 'Eyelet Safe Zone');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 31.35 31.35"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <g>
        <circle
          style="fill:none;stroke:#ff5f2f;stroke-miterlimit:10;stroke-width:3px;"
          cx="15.67"
          cy="15.67"
          r="14.17"
        />
        <line
          style="fill:none;stroke:#ff5f2f;stroke-miterlimit:10;stroke-width:3px;"
          x1="5.89"
          y1="5.42"
          x2="25.93"
          y2="25.45"
        />
        <line
          style="fill:none;stroke:#ff5f2f;stroke-miterlimit:10;stroke-width:3px;"
          x1="11.34"
          y1="2.18"
          x2="29.17"
          y2="20"
        />
        <line
          style="fill:none;stroke:#ff5f2f;stroke-miterlimit:10;stroke-width:3px;"
          x1="2.56"
          y1="10.29"
          x2="21.05"
          y2="28.79"
        />
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const Logo_WEARSHAREIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 1024, 117, 'Logo WEARSHARE');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 362.06 41.31"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <g>
        <path d="M158.59,26.22c2.57,3.73,5.12,7.4,7.63,11.09.51.75,1.56,1.58.81,2.5-.42.51-1.55.54-2.38.57-1.87.08-3.76,0-5.63.03-1.18.02-2.11-.41-2.77-1.37-2.33-3.43-4.65-6.87-6.95-10.32-.25-.38-.44-.88-.45-1.34-.06-2.19-.06-4.39-.06-6.58,0-.97.53-1.46,1.49-1.5.72-.03,1.44-.02,2.15-.09,2.86-.28,5.05-2.42,5.01-4.87-.04-2.7-2.03-4.77-5.02-4.94-2.39-.14-4.79-.04-7.19-.04-.35,0-.71,0-1.26,0v1.46c0,9.06,0,18.11,0,27.17,0,2.11-.32,2.43-2.41,2.43-1.76,0-3.52.01-5.27,0-1.31-.01-1.74-.42-1.74-1.73-.01-12.01-.01-24.02,0-36.03,0-1.34.4-1.71,1.72-1.71,5.63,0,11.27-.06,16.9.02,3.36.05,6.6.67,9.28,2.94,7.02,5.95,5.47,17.82-2.85,21.84-.31.15-.63.3-1.02.49Z" />
        <path d="M315.58,26.21c2.62,3.81,5.17,7.52,7.73,11.23.18.26.45.5.52.79.14.55.35,1.19.18,1.66-.11.29-.87.47-1.34.48-2.24.05-4.48.01-6.71.03-1.13,0-2.04-.4-2.67-1.32-2.35-3.46-4.7-6.93-7.01-10.43-.29-.44-.43-1.05-.44-1.58-.07-2.07-.09-4.15-.07-6.22,0-.98.55-1.54,1.58-1.57.72-.02,1.44-.01,2.15-.09,2.85-.32,4.93-2.37,4.93-4.8,0-2.69-1.84-4.75-4.82-4.96-2.81-.2-5.65-.04-8.66-.04,0,.53,0,.99,0,1.45,0,9.1,0,18.19,0,27.29,0,1.97-.31,2.27-2.3,2.27-1.76,0-3.52.01-5.27,0-1.4-.01-1.82-.41-1.82-1.76,0-12.01,0-24.02,0-36.03,0-1.26.41-1.68,1.63-1.68,5.71-.01,11.43-.07,17.14.02,3.94.06,7.59,1.03,10.37,4.11,4.53,5.01,5.11,15.8-3.92,20.56-.35.19-.71.35-1.2.59Z" />
        <path d="M176.58,26.83c1.04,0,2.08,0,3.12,0,1.08,0,1.71.44,1.84,1.59.24,2.04,1.45,3.32,3.36,3.96,2.25.75,4.55.83,6.84.18,1.71-.49,2.58-1.62,2.66-3.28.07-1.5-.53-2.49-2.28-3.11-1.76-.63-3.63-.94-5.4-1.54-2.98-1-6.04-1.87-8.85-3.24-7.57-3.7-7.14-14.41-.75-18.56,6.37-4.14,16.81-3.7,22.5,1.18,2.22,1.91,3.27,4.38,3.37,7.27.05,1.42-.35,1.82-1.73,1.83-1.88.02-3.76-.02-5.64.01-.93.02-1.56-.34-1.81-1.22-1.26-4.35-8.03-4.59-10.52-2.72-1.51,1.13-1.74,3.01-.27,4.17,1.17.92,2.55,1.64,3.95,2.15,2.4.87,4.88,1.49,7.32,2.23.91.28,1.82.6,2.71.95,4.12,1.6,6.68,4.43,6.95,8.98.28,4.74-1.27,8.62-5.47,11.19-5.52,3.37-16.67,3.81-22.97-1.94-2.43-2.22-3.69-4.96-3.77-8.24-.03-1.46.34-1.82,1.84-1.83,1,0,2,0,3,0Z" />
        <path d="M267.39,12.72c-1.42,4.22-2.82,8.44-4.26,12.65-1.57,4.55-3.17,9.1-4.77,13.64-.35.99-1.07,1.41-2.13,1.39-2.04-.03-4.08,0-6.11-.01-1.37-.01-1.74-.52-1.26-1.81,4.43-11.95,8.86-23.89,13.31-35.83.57-1.54,1.12-1.86,2.8-1.87,1.64,0,3.28,0,4.91,0,1.99,0,2.5.34,3.19,2.19,1.34,3.58,2.66,7.17,3.98,10.75,2.99,8.11,5.99,16.21,8.97,24.32.69,1.88.42,2.25-1.6,2.25-1.8,0-3.6-.05-5.39.02-1.33.05-2.09-.51-2.48-1.76-.39-1.25-.93-2.46-1.3-3.72-.24-.82-.63-1.12-1.51-1.1-2.6.06-5.19.02-7.79.02-.39,0-.78-.03-1.3-.06.93-2.7,1.79-5.29,2.73-7.85.1-.27.65-.51,1.01-.53,1.14-.07,2.29-.02,3.62-.02-1.48-4.34-2.89-8.5-4.31-12.67-.1,0-.21,0-.31,0Z" />
        <path d="M107.61,33.77c.93-2.7,1.78-5.26,2.71-7.8.1-.28.63-.54.98-.56,1.19-.07,2.39-.03,3.73-.03-1.49-4.37-2.92-8.61-4.45-13.1-1.54,4.52-2.99,8.78-4.45,13.03-1.52,4.41-3.04,8.81-4.57,13.21-.56,1.6-.96,1.87-2.62,1.87-1.84,0-3.68,0-5.51,0-1.72,0-2.04-.47-1.43-2.13,2.09-5.64,4.18-11.28,6.27-16.91,2.23-6.01,4.46-12.02,6.69-18.03.81-2.18,1.17-2.44,3.5-2.44,1.6,0,3.2,0,4.8,0,1.6,0,2.15.36,2.75,1.96,1.32,3.5,2.61,7.02,3.9,10.53,3.05,8.25,6.11,16.51,9.16,24.76.69,1.86.4,2.26-1.6,2.26-1.8,0-3.6-.05-5.39.02-1.33.05-2.1-.52-2.49-1.75-.39-1.21-.88-2.4-1.24-3.62-.26-.89-.69-1.26-1.69-1.23-2.71.08-5.43.03-8.15.03-.23,0-.47-.04-.89-.08Z" />
        <path d="M67.08,9.35v22.59c.51,0,.98,0,1.44,0,5.72,0,11.43,0,17.15,0,1.92,0,2.25.33,2.27,2.2,0,1.48.02,2.95,0,4.43-.03,1.38-.45,1.82-1.79,1.82-8.95.01-17.91.01-26.86,0-1.45,0-1.84-.48-1.84-2.15,0-10.26,0-20.51,0-30.77,0-1.6-.02-3.19,0-4.79.02-1.37.45-1.79,1.81-1.79,8.63,0,17.27,0,25.9,0,1.3,0,1.75.42,1.77,1.7.04,1.6.03,3.19,0,4.79-.02,1.55-.45,1.96-2.03,1.96-5.48,0-10.95,0-16.43,0-.43,0-.86,0-1.39,0Z" />
        <path d="M341.2,9.35v22.53c.5.02.96.06,1.42.06,5.67,0,11.35,0,17.02,0,2.12,0,2.41.3,2.42,2.44,0,1.28,0,2.55,0,3.83-.01,1.81-.39,2.2-2.16,2.2-8.71,0-17.42,0-26.13,0-1.87,0-2.2-.34-2.21-2.25,0-3.15,0-6.3,0-9.46,0-8.5,0-17,0-25.49,0-2.09.24-2.31,2.36-2.31,8.35,0,16.7,0,25.05,0,1.66,0,2.06.37,2.09,2.02.02,1.4.02,2.79,0,4.19-.02,1.87-.4,2.25-2.24,2.25-5.39,0-10.79,0-16.18,0h-1.43Z" />
        <path d="M233.77,25.1h-9.11v-8.98h9.11v-1.42c0-3.75,0-7.5,0-11.26,0-2.36.21-2.56,2.61-2.56,1.6,0,3.2,0,4.8,0,1.79,0,2.1.31,2.11,2.08,0,4.75,0,9.5,0,14.25,0,7.02,0,14.05,0,21.07,0,1.73-.38,2.11-2.1,2.12-1.8,0-3.6,0-5.4,0-1.55,0-2.01-.44-2.01-1.99-.01-3.95,0-7.9,0-11.85,0-.43,0-.86,0-1.45Z" />
        <path d="M29.48,30.89c-1.13-3.57-2.22-7.02-3.42-10.83-.25.72-.39,1.1-.52,1.49-1.77,5.62-3.53,11.25-5.3,16.87-.53,1.69-.96,1.99-2.77,1.99-1.52,0-3.04.02-4.56,0-1.19-.02-1.38-.29-1.08-1.46,1.28-5.02,2.48-10.06,3.87-15.04,1.97-7.05,4.08-14.06,6.15-21.08.48-1.63.9-1.92,2.54-1.93,1.2,0,2.4.04,3.6-.01,1.05-.05,1.7.45,1.98,1.38,1.25,4.24,2.47,8.49,3.67,12.74.07.25.01.56-.06.82-1.26,4.82-2.52,9.63-3.79,14.44-.05.18-.17.34-.32.63Z" />
        <path d="M210.95,20.6c0-5.86,0-11.72,0-17.58,0-1.83.31-2.13,2.17-2.13,1.88,0,3.75-.01,5.63,0,1.27.01,1.63.36,1.66,1.65.03,1.28,0,2.55,0,3.83.04,5.7.11,11.4.11,17.1,0,5.02-.07,10.04-.11,15.06-.01,1.5-.4,1.87-1.95,1.88-1.8,0-3.59,0-5.39,0-1.73,0-2.13-.38-2.13-2.09,0-5.9,0-11.8,0-17.7Z" />
        <path d="M47.3.88c1,0,2,0,3,0,1.4.01,1.79.49,1.39,1.87-.81,2.79-1.66,5.57-2.5,8.35-2.76,9.11-5.53,18.21-8.29,27.32-.53,1.75-.81,1.96-2.6,1.96-1.44,0-2.88.03-4.32,0-1.45-.04-2.04-.81-1.69-2.14,3.17-11.73,6.33-23.45,9.51-35.17.5-1.86.97-2.18,2.86-2.19.88,0,1.76,0,2.64,0Z" />
        <path d="M8.89,31.64c-.75-2.42-1.5-4.84-2.24-7.26C4.51,17.38,2.38,10.37.26,3.36.15,3.02.03,2.67,0,2.32-.05,1.44.23.86,1.27.87c2.24.03,4.47.02,6.71,0,1.07,0,1.77.54,2.06,1.5,1.19,3.92,2.34,7.86,3.47,11.8.09.32.04.72-.05,1.06-1.43,5.47-2.88,10.93-4.32,16.39-.08,0-.17,0-.25.01Z" />
        <path d="M71.17,15.81c1.81,0,3.56,0,5.31,0,2.16,0,4.32-.02,6.47.01,1.39.02,1.85.41,1.9,1.75.06,1.63,0,3.27,0,4.9,0,1.02-.56,1.59-1.54,1.6-4.02.03-8.05.01-12.14.01v-8.27Z" />
        <path d="M345.3,15.81c2.25,0,4.44,0,6.62,0,1.72,0,3.43-.03,5.15,0,1.43.03,1.87.46,1.89,1.87.03,1.59,0,3.19,0,4.78,0,.96-.46,1.57-1.41,1.58-4.06.03-8.11.01-12.24.01v-8.25Z" />
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const Logo_WRSRIcon: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 462, 117, 'Logo WRSR');
  return (
    // *INDENT-OFF*
    <svg
      viewBox="0 0 163.45 41.31"
      width={icon.sigGetWidth()}
      height={icon.sigGetHeight()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{icon.sigGetTitle()}</title>
      <g>
        <path d="M80.51,26.21c2.57,3.73,5.12,7.4,7.63,11.09.51.75,1.56,1.58.81,2.5-.42.51-1.55.54-2.38.57-1.87.08-3.76,0-5.63.03-1.18.02-2.11-.41-2.77-1.37-2.33-3.43-4.65-6.87-6.95-10.32-.25-.38-.44-.88-.45-1.34-.06-2.19-.06-4.39-.06-6.58,0-.97.53-1.46,1.49-1.5.72-.03,1.44-.02,2.15-.09,2.86-.28,5.05-2.42,5.01-4.87-.04-2.7-2.03-4.77-5.02-4.94-2.39-.14-4.79-.04-7.19-.04-.35,0-.71,0-1.26,0v1.46c0,9.06,0,18.11,0,27.17,0,2.11-.32,2.43-2.41,2.43-1.76,0-3.52.01-5.27,0-1.31-.01-1.74-.42-1.74-1.73-.01-12.01-.01-24.02,0-36.03,0-1.34.4-1.71,1.72-1.71,5.63,0,11.27-.06,16.9.02,3.36.05,6.6.67,9.28,2.94,7.02,5.95,5.47,17.82-2.85,21.84-.31.15-.63.3-1.02.49Z" />
        <path d="M154.75,26.21c2.57,3.73,5.12,7.4,7.63,11.09.51.75,1.56,1.58.81,2.5-.42.51-1.55.54-2.38.57-1.87.08-3.76,0-5.63.03-1.18.02-2.11-.41-2.77-1.37-2.33-3.43-4.65-6.87-6.95-10.32-.25-.38-.44-.88-.45-1.34-.06-2.19-.06-4.39-.06-6.58,0-.97.53-1.46,1.49-1.5.72-.03,1.44-.02,2.15-.09,2.86-.28,5.05-2.42,5.01-4.87-.04-2.7-2.03-4.77-5.02-4.94-2.39-.14-4.79-.04-7.19-.04-.35,0-.71,0-1.26,0v1.46c0,9.06,0,18.11,0,27.17,0,2.11-.32,2.43-2.41,2.43-1.76,0-3.52.01-5.27,0-1.31-.01-1.74-.42-1.74-1.73-.01-12.01-.01-24.02,0-36.03,0-1.34.4-1.71,1.72-1.71,5.63,0,11.27-.06,16.9.02,3.36.05,6.6.67,9.28,2.94,7.02,5.95,5.47,17.82-2.85,21.84-.31.15-.63.3-1.02.49Z" />
        <path d="M97.68,26.83c1.04,0,2.08,0,3.12,0,1.08,0,1.71.44,1.84,1.59.24,2.04,1.45,3.32,3.36,3.96,2.25.75,4.55.83,6.84.18,1.71-.49,2.58-1.62,2.66-3.28.07-1.5-.53-2.49-2.28-3.11-1.76-.63-3.63-.94-5.4-1.54-2.98-1-6.04-1.87-8.85-3.24-7.57-3.7-7.14-14.41-.75-18.56,6.37-4.14,16.81-3.7,22.5,1.18,2.22,1.91,3.27,4.38,3.37,7.27.05,1.42-.35,1.82-1.73,1.83-1.88.02-3.76-.02-5.64.01-.93.02-1.56-.34-1.81-1.22-1.26-4.35-8.03-4.59-10.52-2.72-1.51,1.13-1.74,3.01-.27,4.17,1.17.92,2.55,1.64,3.95,2.15,2.4.87,4.88,1.49,7.32,2.23.91.28,1.82.6,2.71.95,4.12,1.6,6.68,4.43,6.95,8.98.28,4.74-1.27,8.62-5.47,11.19-5.52,3.37-16.67,3.81-22.97-1.94-2.43-2.22-3.69-4.96-3.77-8.24-.03-1.46.34-1.82,1.84-1.83,1,0,2,0,3,0Z" />
        <g>
          <path d="M29.48,30.9c-1.13-3.57-2.22-7.02-3.42-10.83-.25.72-.39,1.1-.52,1.49-1.77,5.62-3.53,11.25-5.3,16.87-.53,1.69-.96,1.99-2.77,1.99-1.52,0-3.04.02-4.56,0-1.19-.02-1.38-.29-1.08-1.46,1.28-5.02,2.48-10.06,3.87-15.04,1.97-7.05,4.08-14.06,6.15-21.08.48-1.63.9-1.92,2.54-1.93,1.2,0,2.4.04,3.6-.01,1.05-.05,1.7.45,1.98,1.38,1.25,4.24,2.47,8.49,3.67,12.74.07.25.01.56-.06.82-1.26,4.82-2.52,9.63-3.79,14.44-.05.18-.17.34-.32.63Z" />
          <path d="M47.3.9c1,0,2,0,3,0,1.4.01,1.79.49,1.39,1.87-.81,2.79-1.66,5.57-2.5,8.35-2.76,9.11-5.53,18.21-8.29,27.32-.53,1.75-.81,1.96-2.6,1.96-1.44,0-2.88.03-4.32,0-1.45-.04-2.04-.81-1.69-2.14,3.17-11.73,6.33-23.45,9.51-35.17.5-1.86.97-2.18,2.86-2.19.88,0,1.76,0,2.64,0Z" />
          <path d="M8.89,31.65c-.75-2.42-1.5-4.84-2.24-7.26C4.51,17.39,2.38,10.38.26,3.37.15,3.03.03,2.68,0,2.33-.05,1.46.23.87,1.27.89c2.24.03,4.47.02,6.71,0,1.07,0,1.77.54,2.06,1.5,1.19,3.92,2.34,7.86,3.47,11.8.09.32.04.72-.05,1.06-1.43,5.47-2.88,10.93-4.32,16.39-.08,0-.17,0-.25.01Z" />
        </g>
      </g>
    </svg>
    // *INDENT-ON*
  );
};
export const BandEnd0: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 1024, 1024, 'Band End');
  const viewDim = 33.86666;
  const eyeletRadius = 179 + 25; // px
  const eyeletDim = (viewDim * (2 * eyeletRadius)) / 1024;
  const eyeletOff = (viewDim - eyeletDim) * 0.5;
  const WRSR_w = (462 / 1024) * viewDim;
  const WEARSHARE_w = viewDim;
  const font_h = (110 / 1024) * viewDim;
  const WRSROffX = (viewDim - WRSR_w) * 0.5;
  const font_Y = viewDim - (117 / 1024) * viewDim;
  return (
    // *INDENT-OFF*
    <div
      id={icon.sigGetId()}
      class={icon.sigGetClass()}
      style={icon.sigGetStyle()}
      onClick={icon.sigGetOnClick()}
      title={icon.sigGetTitle()}
    >
      <svg
        viewBox={'0 0 ' + viewDim + ' ' + viewDim}
        style={icon.sigGetStyleSVG()}
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{icon.sigGetTitle()}</title>
        <g
          transform={
            'rotate(' +
            icon.sigGetRotation() +
            ' ' +
            viewDim / 2 +
            ' ' +
            viewDim / 2 +
            ')'
          }
        >
          <rect x="0" y="0" width={viewDim} height={viewDim} fill="#FBFF53" />
          <TabWRSRWigglesIcon height={viewDim} width={viewDim} />
          <g transform={'translate(' + eyeletOff + ',' + eyeletOff + ')'}>
            <EyeletSafeZoneIcon height={eyeletDim} width={eyeletDim} />
          </g>
          <g transform={'translate(' + WRSROffX + ',' + font_Y + ')'}>
            <Logo_WRSRIcon height={font_h} width={WRSR_w} />
          </g>
        </g>
      </svg>
    </div>
    // *INDENT-ON*
  );
};
export const BandEnd1: Component<IconProps> = (props) => {
  const icon = new DynamicIcon(props, 1024, 1024, 'Band End');
  const viewDim = 33.86666;
  const eyeletRadius = 179 + 25; // px
  const eyeletDim = (viewDim * (2 * eyeletRadius)) / 1024;
  const eyeletOff = (viewDim - eyeletDim) * 0.5;
  const WRSR_w = (462 / 1024) * viewDim;
  const WEARSHARE_w = viewDim;
  const font_h = (110 / 1024) * viewDim;
  const WRSROffX = (viewDim - WRSR_w) * 0.5;
  const WEARSHAREOffX = 0;
  const font_Y = viewDim - (117 / 1024) * viewDim;
  return (
    // *INDENT-OFF*
    <div
      id={icon.sigGetId()}
      class={icon.sigGetClass()}
      style={icon.sigGetStyle()}
      onClick={icon.sigGetOnClick()}
      title={icon.sigGetTitle()}
    >
      <svg
        viewBox={'0 0 ' + viewDim + ' ' + viewDim}
        style={icon.sigGetStyleSVG()}
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{icon.sigGetTitle()}</title>
        <g
          transform={
            'rotate(' +
            icon.sigGetRotation() +
            ' ' +
            viewDim / 2 +
            ' ' +
            viewDim / 2 +
            ')'
          }
        >
          <rect x="0" y="0" width={viewDim} height={viewDim} fill="#FBFF53" />
          <TabWRSRWigglesIcon height={viewDim} width={viewDim} />
          <g transform={'translate(' + eyeletOff + ',' + eyeletOff + ')'}>
            <EyeletSafeZoneIcon height={eyeletDim} width={eyeletDim} />
          </g>
          <g transform={'translate(' + WEARSHAREOffX + ',' + font_Y + ')'}>
            <Logo_WEARSHAREIcon height={font_h} width={WEARSHARE_w} />
          </g>
        </g>
      </svg>
    </div>
    // *INDENT-ON*
  );
};
