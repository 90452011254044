import { mergeProps, createSignal } from 'solid-js';

export class IconProps {
  // dimension width in px
  width: number;
  // dimension height in px
  height: number;
  // Four possible values: 0, 90, 180, 270
  rotation: number;
  // index when there are multiple images to choose from
  index: number;
  // whether to show as selected
  selected: string;
  // the id to use for the top level DOM object
  id: string;
  // the CSS class
  classCSS: string;
  // the CSS style
  styleCSS: string;
  // the CSS style for the SVG
  styleSVG: string;
  // onClick function
  onclick: Function;
  // the title, and tooltip
  title: string;
}
const defaultIconProps = {
  rotation: 0,
  index: 0,
  selected: 'false',
  classCSS: '',
  styleCSS: '',
  styleSVG: '',
} as IconProps;

export class DynamicIcon {
  sigGetSelected: Function;
  sigSetSelected: Function;
  iconProps: IconProps;
  constructor(
    props: IconProps = {},
    nativeWidth: number = 64,
    nativeHeight: number = 64,
    title: string = ''
  ) {
    const aspect = nativeWidth / nativeHeight;
    const myProps = defaultIconProps;
    if (!Object.hasOwn(props, 'width')) {
      if (Object.hasOwn(props, 'height')) {
        myProps.width = parseInt(aspect * parseInt(props.height));
      } else {
        myProps.width = parseInt(nativeWidth);
        myProps.height = parseInt(nativeHeight);
      }
    } else if (!Object.hasOwn(props, 'height')) {
      myProps.height = Math.round(parseInt(props.width) / aspect);
    }
    if (title.length > 0) {
      myProps.title = title;
    }
    this.iconProps = mergeProps(myProps, props);
    [this.sigGetSelected, this.sigSetSelected] = createSignal(
      this.iconProps.selected
    );
  }
  public sigGetWidth(): number {
    return this.iconProps.width;
  }
  public sigGetHeight(): number {
    return this.iconProps.height;
  }
  public sigGetTitle(): number {
    return this.iconProps.title;
  }
  public sigGetRotation(): number {
    return this.iconProps.rotation;
  }
  public sigGetIndex(): number {
    return this.iconProps.index;
  }
  public sigGetId(): string {
    return this.iconProps.id;
  }
  public sigGetClass(): string {
    return this.iconProps.classCSS;
  }
  public sigGetStyle(): string {
    return this.iconProps.styleCSS;
  }
  public sigGetStyleSVG(): string {
    return this.iconProps.styleSVG;
  }
  public sigGetOnClick(): Function {
    return this.iconProps.onclick;
  }

  public isVertical(): boolean {
    return this.iconProps.rotation === 90 || this.iconProps.rotation === 270;
  }
  public rotatedWidth(): number {
    return this.isVertical() ? this.sigGetHeight() : this.sigGetWidth();
  }
  public rotatedHeight(): number {
    return this.isVertical() ? this.sigGetWidth() : this.sigGetHeight();
  }
  public translateX(viewWidth: number, viewHeight: number): number {
    let transX = 0;
    switch (parseInt(this.sigGetRotation())) {
      case 90:
        transX = viewHeight;
        break;
      case 180:
        transX = viewWidth;
        break;
      default:
        break;
    }
    return transX;
  }
  public translateY(viewWidth: number, viewHeight: number): number {
    let transY = 0;
    switch (parseInt(this.sigGetRotation())) {
      case 180:
        transY = viewHeight;
        break;
      case 270:
        transY = viewWidth;
        break;
      default:
        break;
    }
    return transY;
  }
}
