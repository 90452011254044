import type { JSX, Component } from 'solid-js';
import { Show, Switch, Match } from 'solid-js';
import { CropFrame, Effect } from '~/shared/CoreImageOperation';
import {
  PartitionProps,
  DynamicPartition,
} from '~/components/designer2/DynamicPartition';
import { DynamicBand } from '~/components/designer2/DynamicBand';
import {
  BlankPartitionPlain,
  CropCircleIcon,
  CropOvalIcon,
  CropSquareIcon,
  CropSplashIcon,
  CropStarIcon,
  CropBackSquareIcon,
  CropForwardSquareIcon,
  WaitIcon,
} from '~/components/designer2/IconComponents';

type Component<P = { object }> = (props: P) => JSX.Element;

export const PartitionComponent: Component<PartitionProps> = (props) => {
  const partition = new DynamicPartition(props);
  DynamicBand.setPartition(partition);
  return (
    // *INDENT-OFF*
    <div
      id={partition.sigGetId()}
      class={partition.sigGetClass()}
      style={partition.sigGetStyle()}
      onClick={partition.sigGetOnClick()}
      onDragenter={partition.sigGetOnDragenter()}
      onDragover={partition.sigGetOnDragover()}
      onDragleave={partition.sigGetOnDragleave()}
      onDrop={partition.sigGetOnDrop()}
      draggable={partition.sigGetDraggable()}
      onDragstart={partition.sigGetOnDragstart()}
      onDrag={partition.sigGetOnDrag()}
      onDragend={partition.sigGetOnDragend()}
      onPointerdown={partition.sigGetOnPointerdown()}
      onPointermove={partition.sigGetOnPointermove()}
      onPointerup={partition.sigGetOnPointerup()}
      onPointercancel={partition.sigGetOnPointercancel()}
      onPointerout={partition.sigGetOnPointerout()}
      onPointerenter={partition.sigGetOnPointerenter()}
      onPointerover={partition.sigGetOnPointerover()}
      onPointerleave={partition.sigGetOnPointerleave()}
    >
      <svg
        id={partition.sigGetSVGId()}
        viewBox="-512 -512 1024 1024"
        style={partition.sigGetStyleSVG()}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <mask id="cropnone">
            <rect x="-512" y="-512" width="1024" height="1024" fill="white" />
          </mask>
          <mask id="cropcircle">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropCircleIcon width="1024" />
            </g>
          </mask>
          <mask id="cropoval">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropOvalIcon width="1024" />
            </g>
          </mask>
          <mask id="cropsquare">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropSquareIcon width="1024" />
            </g>
          </mask>
          <mask id="cropsplash">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropSplashIcon width="1024" />
            </g>
          </mask>
          <mask id="cropstar">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropStarIcon width="1024" />
            </g>
          </mask>
          <mask id="cropbacksquare">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropBackSquareIcon width="1024" />
            </g>
          </mask>
          <mask id="cropforwardsquare">
            <rect x="-512" y="-512" width="1024" height="1024" fill="black" />
            <g transform="translate(-512 -512)">
              <CropForwardSquareIcon width="1024" />
            </g>
          </mask>
        </defs>
        <filter
          id={partition.sigGetFilterId()}
          x="0"
          y="0"
          color-interpolation-filters="sRGB"
        >
          <feComponentTransfer>
            <feFuncR type="linear" slope={partition.sigGetImgBrightness()} />
            <feFuncG type="linear" slope={partition.sigGetImgBrightness()} />
            <feFuncB type="linear" slope={partition.sigGetImgBrightness()} />
          </feComponentTransfer>
          <Show when={partition.sigGetImgContrast() !== 1}>
            <feComponentTransfer>
              <feFuncR
                type="linear"
                slope={partition.sigGetImgContrast()}
                intercept={-(0.5 * partition.sigGetImgContrast()) + 0.5}
              />
              <feFuncG
                type="linear"
                slope={partition.sigGetImgContrast()}
                intercept={-(0.5 * partition.sigGetImgContrast()) + 0.5}
              />
              <feFuncB
                type="linear"
                slope={partition.sigGetImgContrast()}
                intercept={-(0.5 * partition.sigGetImgContrast()) + 0.5}
              />
            </feComponentTransfer>
          </Show>
          <Switch>
            <Match when={partition.sigGetImgEffect() === Effect.warm}>
              <feComponentTransfer>
                <feFuncR type="linear" slope={1.1} />
                <feFuncG type="linear" slope={1.1} />
                <feFuncB type="linear" slope={1.1} />
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncR
                  type="linear"
                  slope={1.15}
                  intercept={-(0.5 * 1.15) + 0.5}
                />
                <feFuncG
                  type="linear"
                  slope={1.15}
                  intercept={-(0.5 * 1.15) + 0.5}
                />
                <feFuncB
                  type="linear"
                  slope={1.15}
                  intercept={-(0.5 * 1.15) + 0.5}
                />
              </feComponentTransfer>
              <feColorMatrix result="blendin1" type="saturate" values={1.25} />
              <feFlood
                result="warmoverlay"
                x="-512"
                y="-512"
                width="100%"
                height="100%"
                flood-color="#fae900"
                flood-opacity="0.23"
              />
              <feBlend in="blendin1" in2="warmoverlay" mode="overlay" />
            </Match>
            <Match when={partition.sigGetImgEffect() === Effect.vintage}>
              <feColorMatrix
                result="blendin1"
                type="matrix"
                values={
                  0.393 +
                  0.607 * (1 - 1.0) +
                  ' ' +
                  (0.769 - 0.769 * (1 - 1.0)) +
                  ' ' +
                  (0.189 - 0.189 * (1 - 1.0)) +
                  ' 0 0 ' +
                  (0.349 - 0.349 * (1 - 1.0)) +
                  ' ' +
                  (0.686 + 0.314 * (1 - 1.0)) +
                  ' ' +
                  (0.168 - 0.168 * (1 - 1.0)) +
                  ' 0 0 ' +
                  (0.272 - 0.272 * (1 - 1.0)) +
                  ' ' +
                  (0.534 - 0.534 * (1 - 1.0)) +
                  ' ' +
                  (0.131 + 0.869 * (1 - 1.0)) +
                  ' 0 0 ' +
                  '0 0 0 1 0'
                }
              />
              <feTurbulence
                baseFrequency="0.01 0.005"
                seed="0"
                xresult="colorNoise"
              />
              <feBlend in="blendin1" in2="colorNoise" mode="multiply" />
            </Match>
            <Match when={partition.sigGetImgEffect() === Effect.cool}>
              <feComponentTransfer>
                <feFuncR type="linear" slope={1.1} />
                <feFuncG type="linear" slope={1.1} />
                <feFuncB type="linear" slope={1.1} />
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncR
                  type="linear"
                  slope={1.1}
                  intercept={-(0.5 * 1.1) + 0.5}
                />
                <feFuncG
                  type="linear"
                  slope={1.1}
                  intercept={-(0.5 * 1.1) + 0.5}
                />
                <feFuncB
                  type="linear"
                  slope={1.1}
                  intercept={-(0.5 * 1.1) + 0.5}
                />
              </feComponentTransfer>
              <feColorMatrix
                type="matrix"
                values={
                  0.2126 +
                  0.7874 * (1 - 0.1) +
                  ' ' +
                  (0.7152 - 0.7152 * (1 - 0.1)) +
                  ' ' +
                  (0.0722 - 0.0722 * (1 - 0.1)) +
                  ' 0 0 ' +
                  (0.2126 - 0.2126 * (1 - 0.1)) +
                  ' ' +
                  (0.7152 + 0.2848 * (1 - 0.1)) +
                  ' ' +
                  (0.0722 - 0.0722 * (1 - 0.1)) +
                  ' 0 0 ' +
                  (0.2126 - 0.2126 * (1 - 0.1)) +
                  ' ' +
                  (0.7152 - 0.7152 * (1 - 0.1)) +
                  ' ' +
                  (0.0722 + 0.9278 * (1 - 0.1)) +
                  ' 0 0 ' +
                  '0 0 0 1 0'
                }
              />
              <feColorMatrix
                type="matrix"
                values={
                  0.393 +
                  0.607 * (1 - 0.5) +
                  ' ' +
                  (0.769 - 0.769 * (1 - 0.5)) +
                  ' ' +
                  (0.189 - 0.189 * (1 - 0.5)) +
                  ' 0 0 ' +
                  (0.349 - 0.349 * (1 - 0.5)) +
                  ' ' +
                  (0.686 + 0.314 * (1 - 0.5)) +
                  ' ' +
                  (0.168 - 0.168 * (1 - 0.5)) +
                  ' 0 0 ' +
                  (0.272 - 0.272 * (1 - 0.5)) +
                  ' ' +
                  (0.534 - 0.534 * (1 - 0.5)) +
                  ' ' +
                  (0.131 + 0.869 * (1 - 0.5)) +
                  ' 0 0 ' +
                  '0 0 0 1 0'
                }
              />
              <feColorMatrix
                result="blendin1"
                type="saturate"
                values={1 - 0.85}
              />
              <feFlood
                result="coolmultiply"
                x="-512"
                y="-512"
                width="100%"
                height="100%"
                flood-color="#00e5fa"
                flood-opacity="0.13"
              />
              <feBlend in="blendin1" in2="coolmultiply" mode="multiply" />
            </Match>
            <Match when={partition.sigGetImgEffect() === Effect.faded}>
              <feComponentTransfer>
                <feFuncR type="linear" slope={1.2} />
                <feFuncG type="linear" slope={1.2} />
                <feFuncB type="linear" slope={1.2} />
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncR
                  type="linear"
                  slope={0.7}
                  intercept={-(0.5 * 0.7) + 0.5}
                />
                <feFuncG
                  type="linear"
                  slope={0.7}
                  intercept={-(0.5 * 0.7) + 0.5}
                />
                <feFuncB
                  type="linear"
                  slope={0.7}
                  intercept={-(0.5 * 0.7) + 0.5}
                />
              </feComponentTransfer>
              <feColorMatrix type="saturate" values={1 - 0.75} />
            </Match>
            <Match when={partition.sigGetImgEffect() === Effect.graphite}>
              <feColorMatrix
                type="matrix"
                values={
                  0.2126 +
                  0.7874 * (1 - 1.0) +
                  ' ' +
                  (0.7152 - 0.7152 * (1 - 1.0)) +
                  ' ' +
                  (0.0722 - 0.0722 * (1 - 1.0)) +
                  ' 0 0 ' +
                  (0.2126 - 0.2126 * (1 - 1.0)) +
                  ' ' +
                  (0.7152 + 0.2848 * (1 - 1.0)) +
                  ' ' +
                  (0.0722 - 0.0722 * (1 - 1.0)) +
                  ' 0 0 ' +
                  (0.2126 - 0.2126 * (1 - 1.0)) +
                  ' ' +
                  (0.7152 - 0.7152 * (1 - 1.0)) +
                  ' ' +
                  (0.0722 + 0.9278 * (1 - 1.0)) +
                  ' 0 0 ' +
                  '0 0 0 1 0'
                }
              />
              <feComponentTransfer>
                <feFuncR type="linear" slope={1.2} />
                <feFuncG type="linear" slope={1.2} />
                <feFuncB type="linear" slope={1.2} />
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncR
                  type="linear"
                  slope={1.3}
                  intercept={-(0.5 * 1.3) + 0.5}
                />
                <feFuncG
                  type="linear"
                  slope={1.3}
                  intercept={-(0.5 * 1.3) + 0.5}
                />
                <feFuncB
                  type="linear"
                  slope={1.3}
                  intercept={-(0.5 * 1.3) + 0.5}
                />
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncR
                  type="discrete"
                  tableValues={PartitionProps.posterizeLevels(1)}
                />
                <feFuncG
                  type="discrete"
                  tableValues={PartitionProps.posterizeLevels(1)}
                />
                <feFuncB
                  type="discrete"
                  tableValues={PartitionProps.posterizeLevels(1)}
                />
              </feComponentTransfer>
            </Match>
          </Switch>
          <Show when={partition.sigGetImgInvert() > 0}>
            <feComponentTransfer>
              <feFuncR
                type="table"
                tableValues={
                  partition.sigGetImgInvert() +
                  ' ' +
                  (1 - partition.sigGetImgInvert())
                }
              />
              <feFuncG
                type="table"
                tableValues={
                  partition.sigGetImgInvert() +
                  ' ' +
                  (1 - partition.sigGetImgInvert())
                }
              />
              <feFuncB
                type="table"
                tableValues={
                  partition.sigGetImgInvert() +
                  ' ' +
                  (1 - partition.sigGetImgInvert())
                }
              />
            </feComponentTransfer>
          </Show>
          <Show when={partition.sigGetImgHueRotate() !== 0}>
            <feColorMatrix
              type="hueRotate"
              values={partition.sigGetImgHueRotate()}
            />
          </Show>
          <Show when={partition.sigGetImgSaturate() !== 1}>
            <feColorMatrix
              type="saturate"
              values={partition.sigGetImgSaturate()}
            />
          </Show>
          <Show when={partition.sigGetImgGrayscale() > 0}>
            <feColorMatrix
              type="matrix"
              values={
                0.2126 +
                0.7874 * (1 - partition.sigGetImgGrayscale()) +
                ' ' +
                (0.7152 - 0.7152 * (1 - partition.sigGetImgGrayscale())) +
                ' ' +
                (0.0722 - 0.0722 * (1 - partition.sigGetImgGrayscale())) +
                ' 0 0 ' +
                (0.2126 - 0.2126 * (1 - partition.sigGetImgGrayscale())) +
                ' ' +
                (0.7152 + 0.2848 * (1 - partition.sigGetImgGrayscale())) +
                ' ' +
                (0.0722 - 0.0722 * (1 - partition.sigGetImgGrayscale())) +
                ' 0 0 ' +
                (0.2126 - 0.2126 * (1 - partition.sigGetImgGrayscale())) +
                ' ' +
                (0.7152 - 0.7152 * (1 - partition.sigGetImgGrayscale())) +
                ' ' +
                (0.0722 + 0.9278 * (1 - partition.sigGetImgGrayscale())) +
                ' 0 0 ' +
                '0 0 0 1 0'
              }
            />
          </Show>
          <Show when={partition.sigGetImgSepia() > 0}>
            <feColorMatrix
              type="matrix"
              values={
                0.393 +
                0.607 * (1 - partition.sigGetImgSepia()) +
                ' ' +
                (0.769 - 0.769 * (1 - partition.sigGetImgSepia())) +
                ' ' +
                (0.189 - 0.189 * (1 - partition.sigGetImgSepia())) +
                ' 0 0 ' +
                (0.349 - 0.349 * (1 - partition.sigGetImgSepia())) +
                ' ' +
                (0.686 + 0.314 * (1 - partition.sigGetImgSepia())) +
                ' ' +
                (0.168 - 0.168 * (1 - partition.sigGetImgSepia())) +
                ' 0 0 ' +
                (0.272 - 0.272 * (1 - partition.sigGetImgSepia())) +
                ' ' +
                (0.534 - 0.534 * (1 - partition.sigGetImgSepia())) +
                ' ' +
                (0.131 + 0.869 * (1 - partition.sigGetImgSepia())) +
                ' 0 0 ' +
                '0 0 0 1 0'
              }
            />
          </Show>
          <Show when={partition.sigGetImgSharpen() > 0}>
            <feConvolveMatrix
              kernelMatrix={
                '0 ' +
                -partition.sigGetImgSharpen() +
                ' 0 ' +
                -partition.sigGetImgSharpen() +
                ' ' +
                (1 + 4 * partition.sigGetImgSharpen()) +
                ' ' +
                -partition.sigGetImgSharpen() +
                ' ' +
                '0 ' +
                -partition.sigGetImgSharpen() +
                ' 0'
              }
            />
          </Show>
          <Show when={partition.sigGetImgBlur() > 0}>
            <feGaussianBlur stdDeviation={partition.sigGetImgBlur()} />
          </Show>
          <Show when={partition.sigGetImgPosterize() < 16}>
            <feComponentTransfer>
              <feFuncR
                type="discrete"
                tableValues={partition.posterizeLevels()}
              />
              <feFuncG
                type="discrete"
                tableValues={partition.posterizeLevels()}
              />
              <feFuncB
                type="discrete"
                tableValues={partition.posterizeLevels()}
              />
            </feComponentTransfer>
          </Show>
        </filter>
        <rect
          x="-512"
          y="-512"
          width="1024"
          height="1024"
          fill={partition.sigGetBgColor()}
          stroke-width="0"
          stroke="none"
        />
        <Show
          when={
            partition.sigGetIndex() > 0 &&
            partition.sigGetSelected() === 'false'
          }
        >
          <g transform="translate(-512,-512)">
            <BlankPartitionPlain selected={partition.sigGetSelected()} />
          </g>
        </Show>
        <g
          style={
            'mask: url(#crop' + CropFrame[partition.sigGetImgCropFrame()] + ');'
          }
        >
          <g
            transform={
              'translate(' +
              Math.round(partition.sigGetImgTranslateX() * 512.0) +
              ' ' +
              Math.round(partition.sigGetImgTranslateY() * 512.0) +
              ') ' +
              'scale(' +
              partition.sigGetImgScale() +
              ') ' +
              'rotate(' +
              partition.sigGetImgRotation() +
              ') '
            }
          >
            <image
              x="-512"
              y="-512"
              width="1024"
              height="1024"
              href={partition.sigGetImgSrc()}
              filter={'url(#' + partition.sigGetFilterId() + ')'}
            />
          </g>
        </g>
        <image
          x="232"
          y="-480"
          width="264"
          height="264"
          href={PartitionProps.getStickerDataurl(partition.sigGetImgSticker())}
        />
        <Show
          when={
            partition.sigGetIndex() > 0 && partition.sigGetSelected() === 'true'
          }
        >
          <g transform="translate(-512,-512)">
            <BlankPartitionPlain selected={partition.sigGetSelected()} />
          </g>
        </Show>
        <Show when={partition.isLoading()}>
          <g opacity="0.75" transform="translate(-256,-256)">
            <WaitIcon width="512" />
          </g>
        </Show>
      </svg>
    </div>
    // *INDENT-ON*
  );
};

// *INDENT-OFF*
/*
<text x="0" y="256" font-family="mono" font-size="712px" font-weight="bold" text-anchor="middle" fill="#606060A0">{partition.sigGetIndex()}</text>
<text font-size="192px" text-anchor="middle" alignment-baseline="middle" x="320" y="-320">{partition.sigGetImgSticker()}</text>
<rect
x="-512" y="-512"
width="1024"
height="1024"
fill="none"
stroke-width="16"
stroke="#60606080"
				/>
*/
// *INDENT-ON*
