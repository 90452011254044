import type { JSX, Component } from 'solid-js';
import { For } from 'solid-js';

import {
  LogLevel,
  LogProps,
  DynamicLog,
} from '~/components/designer2/DynamicLog';

type Component<P = { object }> = (props: P) => JSX.Element;

export const LogComponent: Component<LogProps> = (props) => {
  const log = new DynamicLog(props);
  return (
    // *INDENT-OFF*
    <div class="w-[1024px] h-[512px] overflow-x-auto overflow-y-auto">
      <span id="logerror" class="text-blue-600 w-[70%] max-w-[512px]">
        {log.sigGetMessage()}
      </span>
      <br />
      <div class="flex">
        <button
          class="bg-wearsharepink rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
          onClick={() => {
            try {
              navigator.clipboard.writeText(log.sigGetContent().join('\n'));
              log.sigSetMessage('Copied to clipboard');
            } catch (error) {
              log.sigSetMessage(
                'Please enable clipboard in your browser: ' + error.message
              );
            }
          }}
        >
          copy log to clipboard
        </button>
        <button
          class="bg-wearshareblue rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
          onClick={() => {
            const x = window.open(
              'mailto:wrsrlog@scarletline.com?subject=CYO+Issue+%5Benter+issue+here%5D&body=%5Bplease+paste+log+here%5D',
              'maillog'
            );
            log.sigSetMessage(
              'email opened - please go to email tab (may need to authorize popup)'
            );
          }}
        >
          eMail log
        </button>
        <a
          class="bg-wearshareorange rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
          href="https://app.slack.com/client/T07KYQJHRU2/D084GP2QNTX"
          target="slack"
        >
          Post to Slack (CTRL-SHIFT-V snippet plain-text)
        </a>
        <a
          class="bg-wearsharetomato rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
          href="https://github.com/WEARSHARE/sites/issues"
          target="github"
        >
          Post to Github issue
        </a>
      </div>
      <table class="border-black border-[2px]">
        <caption>{'Log: ' + LogLevel[log.sigGetLevel()]}</caption>
        <tbody>
          <For each={log.sigGetContent()}>
            {(item) => (
              <tr class="border-black border-[1px] text-left text-xs">
                <td>{item}</td>
              </tr>
            )}
          </For>
        </tbody>
      </table>
    </div>
    // *INDENT-ON*
  );
};
