import type { JSX, Component } from 'solid-js';
import { Show, Switch, Match, onMount, onCleanup } from 'solid-js';
import { useLocation } from '@solidjs/router';
import { BandProps, DynamicBand } from '~/components/designer2/DynamicBand';
import {
  defaultPartitionProps,
  PartitionProps,
  minPartitionProps,
  maxPartitionProps,
  stepPartitionProps,
} from '~/components/designer2/DynamicPartition';
import { PartitionComponent } from '~/components/designer2/PartitionComponent';
import {
  BandEnd0,
  BandEnd1,
  RotateIcon,
  TurnIcon,
  LinkIcon,
  UnlinkIcon,
  UploadIcon,
  PlusIcon,
  GalleryIcon,
  CopyIcon,
  SubmitIcon,
  UndoIcon,
  RedoIcon,
  ClearIcon,
  ResetSettingsIcon,
  CloseIcon,
  MoveIcon,
  CropIcon,
  EffectIcon,
  BrightnessContrastIcon,
  GrayscaleSepiaIcon,
  HueSaturationIcon,
  PosterizeIcon,
  SharpenBlurIcon,
  BackgroundIcon,
  StickerIcon,
  CropCircleIcon,
  CropOvalIcon,
  CropSquareIcon,
  CropSplashIcon,
  CropStarIcon,
  CropBackSquareIcon,
  CropForwardSquareIcon,
  TabWRSRWigglesIcon,
  EyeletSafeZoneIcon,
  Logo_WEARSHAREIcon,
  Logo_WRSRIcon,
} from '~/components/designer2/IconComponents';
import {
  ImageOperationType,
  CropFrame,
  Effect,
} from '~/shared/CoreImageOperation';
import { Confetti } from '@repo/ui/Confetti';

type Component<P = { object }> = (props: P) => JSX.Element;

export function searchParam(name: string, option: string): boolean {
  let value = false;
  for (const search of useLocation().search.substring(1).split('&')) {
    const searchparam = search.split('=');
    if (searchparam.length > 1) {
      const sname = searchparam[0].trim();
      if (sname === name || sname === option) {
        const svalue = searchparam[1].trim();
        if (
          svalue === 'true' ||
          svalue === '1' ||
          svalue === 1 ||
          svalue === true
        ) {
          value = true;
        }
      }
    }
  }
  return value;
}

export const BandComponent: Component<BandProps> = (props) => {
  const band = new DynamicBand(props);
  band.bandProps.alpha = searchParam('alpha', 'a') ? 'true' : 'false';
  band.bandProps.showDebugConsole = searchParam('console', 'c');
  band.bandProps.isIFrame = searchParam('iframe', 'i');
  const bandPartitionCSSH = 'cursor-pointer shrink self-start';
  const bandPartitionCSSV = 'cursor-pointer shrink self-start';
  const buttonDim = 88; // side measurement of main icon buttons - was 64
  const smallButtonDim = 48; // side measurement of main icon buttons - was 32
  let partition0clear, partition0add;
  const onResize = () => {
    DynamicBand.resize();
  };
  onMount(() => {
    band.selectPartition(1);
    DynamicBand.resize();
    window.addEventListener('resize', onResize);
  });
  return (
    // *INDENT-OFF*
    <>
      <Show
        when={band.sigGetFailureMessage().length === 0}
        fallback={
          <span id="partitionerror" class="text-red-600 w-[70%] max-w-[512px]">
            {band.sigGetFailureMessage()}
          </span>
        }
      >
        <Confetti active={band.sigGetShowConfetti()} />
        <Show when={band.isVertical() === false}>
          <div id="headerH" class="w-full">
            <div id="headercenterH" class="mx-auto max-w-fit">
              <div id="headerbuttonsH" class="flex justify-between">
                <div id="buttonsleftH" class="flex">
                  <button
                    id="turnbuttonH"
                    class="my-1 cursor-pointer rounded-lg border-[3px] border-black bg-wearsharelime shadow-lg"
                    onClick={() => {
                      band.toggleOrientation();
                    }}
                  >
                    <TurnIcon width="48" />
                  </button>
                  <label
                    id="imagesbuttonH"
                    class="relative m-0 inline-block cursor-pointer"
                    for="uploadimagesH"
                  >
                    <div
                      class="m-1 rounded-md border-[3px] border-black bg-wearsharemagenta px-1 py-4 align-middle uppercase shadow-lg"
                      title="Upload Image(s)"
                      onDrop={(e) => {
                        e.preventDefault();
                        band.addFiles(
                          e.target.files ||
                            (e.dataTransfer && e.dataTransfer.files)
                        );
                      }}
                      onDragover={(e) => {
                        band.dragOverUpload(e);
                      }}
                    >
                      <b>+ Images</b>
                    </div>
                    <input
                      id="uploadimagesH"
                      type="file"
                      class="absolute top-0 bottom-0 left-0 w-1 opacity-[0]"
                      multiple
                      accept="image/*"
                      onChange={(event) => {
                        band.addFiles(event.target.files);
                      }}
                    />
                  </label>
                </div>
                <button
                  id="clearbuttonH"
                  class="my-1 cursor-pointer rounded-lg border-[3px] border-black bg-wearshareblue shadow-lg"
                  onClick={() => {
                    band.partitionClear(-1);
                  }}
                >
                  <ClearIcon title="Clear All" width="48" />
                </button>
              </div>
              <div
                id="bandH"
                class="flex shrink flex-row flex-nowrap items-center justify-center border-[3px] border-black"
              >
                <Show when={band.sigGetRotation() < 180}>
                  <BandEnd0
                    classCSS="shrink cursor-pointer"
                    styleCSS={
                      'width:' +
                      band.sigGetDim() +
                      'px;max-width:' +
                      band.sigGetDim() +
                      'px;max-height:' +
                      band.sigGetDim() +
                      'px;'
                    }
                    styleSVG="width:100%;"
                    width={band.sigGetDim()}
                    rotation={band.sigGetRotation() + 90}
                  />
                </Show>
                <Show when={band.sigGetRotation() >= 180}>
                  <BandEnd1
                    classCSS="shrink cursor-pointer"
                    styleCSS={
                      'width:' +
                      band.sigGetDim() +
                      'px;max-width:' +
                      band.sigGetDim() +
                      'px;max-height:' +
                      band.sigGetDim() +
                      'px;'
                    }
                    styleSVG="width:100%;"
                    width={band.sigGetDim()}
                    rotation={band.sigGetRotation() - 90}
                  />
                </Show>
                <PartitionComponent
                  index="1"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(1);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(1, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(1);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 1 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="2"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(2);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(2, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(2);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 2 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="3"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(3);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(3, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(3);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 3 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="4"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(4);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(4, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(4);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 4 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="5"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(5);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(5, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(5);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 5 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="6"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(6);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(6, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(6);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 6 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="7"
                  classCSS="shrink cursor-pointer"
                  styleCSS={
                    'width:' +
                    band.sigGetDim() +
                    'px;max-width:' +
                    band.sigGetDim() +
                    'px;max-height:' +
                    band.sigGetDim() +
                    'px;'
                  }
                  styleSVG={'width:100%;'}
                  onclick={() => {
                    band.selectPartition(7);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(7, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(7);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 7 ? 'true' : 'false'
                  }
                />
                <Show when={band.sigGetRotation() < 180}>
                  <BandEnd1
                    classCSS="shrink cursor-pointer"
                    styleCSS={
                      'width:' +
                      band.sigGetDim() +
                      'px;max-width:' +
                      band.sigGetDim() +
                      'px;max-height:' +
                      band.sigGetDim() +
                      'px;'
                    }
                    styleSVG="width:100%;"
                    width={band.sigGetDim()}
                    rotation={band.sigGetRotation() - 90}
                  />
                </Show>
                <Show when={band.sigGetRotation() >= 180}>
                  <BandEnd0
                    classCSS="shrink cursor-pointer"
                    styleCSS={
                      'width:' +
                      band.sigGetDim() +
                      'px;max-width:' +
                      band.sigGetDim() +
                      'px;max-height:' +
                      band.sigGetDim() +
                      'px;'
                    }
                    styleSVG="width:100%;"
                    width={band.sigGetDim()}
                    rotation={band.sigGetRotation() + 90}
                  />
                </Show>
              </div>
            </div>
          </div>
        </Show>
        <Show when={band.isVertical() === true}>
          <div id="buttonsleftV" class="flex">
            <button
              id="turnbuttonV"
              class="m-1 cursor-pointer rounded-lg border-[3px] border-black bg-wearsharelime shadow-lg"
              onClick={() => {
                band.toggleOrientation();
              }}
            >
              <TurnIcon width="48" />
            </button>
            <label
              id="imagesbuttonV"
              class="relative m-0 inline-block cursor-pointer"
              for="uploadimagesV"
            >
              <div
                class="m-1 rounded-md border-[3px] border-black bg-wearsharemagenta px-1 py-3 align-middle uppercase shadow-lg"
                title="Upload Image(s)"
                onDrop={(e) => {
                  e.preventDefault();
                  band.addFiles(
                    e.target.files || (e.dataTransfer && e.dataTransfer.files)
                  );
                }}
                onDragover={(e) => {
                  band.dragOverUpload(e);
                }}
              >
                <b>+ Images</b>
              </div>
              <input
                id="uploadimagesV"
                type="file"
                class="absolute top-0 bottom-0 left-0 w-1 opacity-[0]"
                multiple
                accept="image/*"
                onChange={(event) => {
                  band.addFiles(event.target.files);
                }}
              />
            </label>
          </div>
        </Show>
        <div id="content" class="flex w-full">
          <Show when={band.isVertical() === true}>
            <div id="headerV" class="w-fit">
              <button
                id="clearbuttonV"
                class="m-1 cursor-pointer rounded-lg border-[3px] border-black bg-wearshareblue shadow-lg"
                onClick={() => {
                  band.partitionClear(-1);
                }}
              >
                <ClearIcon title="Clear All" width="48" />
              </button>
              <div
                id="bandV"
                class="flex w-[86px] flex-col flex-nowrap border-[3px] border-black"
              >
                <Show when={band.sigGetRotation() < 180}>
                  <BandEnd0
                    classCSS="cursor-pointer"
                    styleCSS={
                      'height:' +
                      band.sigGetVDim() +
                      'px;max-width:' +
                      band.sigGetVDim() +
                      'px;max-height:' +
                      band.sigGetVDim() +
                      'px;'
                    }
                    styleSVG={'height:100%;'}
                    height={band.sigGetVDim()}
                    rotation={band.sigGetRotation() + 90}
                  />
                </Show>
                <Show when={band.sigGetRotation() >= 180}>
                  <BandEnd1
                    classCSS="cursor-pointer"
                    styleCSS={
                      'height:' +
                      band.sigGetVDim() +
                      'px;max-width:' +
                      band.sigGetVDim() +
                      'px;max-height:' +
                      band.sigGetVDim() +
                      'px;'
                    }
                    styleSVG={'height:100%;'}
                    height={band.sigGetVDim()}
                    rotation={band.sigGetRotation() - 90}
                  />
                </Show>
                <PartitionComponent
                  index="1"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(1);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(1, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(1);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 1 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="2"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(2);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(2, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(2);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 2 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="3"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(3);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(3, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(3);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 3 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="4"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(4);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(4, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(4);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 4 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="5"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(5);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(5, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(5);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 5 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="6"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(6);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(6, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(6);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 6 ? 'true' : 'false'
                  }
                />
                <PartitionComponent
                  index="7"
                  classCSS="cursor-pointer"
                  styleCSS={
                    'height:' +
                    band.sigGetVDim() +
                    'px;max-width:' +
                    band.sigGetVDim() +
                    'px;max-height:' +
                    band.sigGetVDim() +
                    'px;'
                  }
                  styleSVG={'height:100%;'}
                  onclick={() => {
                    band.selectPartition(7);
                  }}
                  ondrop={(e) => {
                    e.preventDefault();
                    band.dropOnPartition(7, e);
                  }}
                  ondragover={(e) => {
                    e.preventDefault();
                  }}
                  draggable="true"
                  ondragstart={() => {
                    band.selectDraggingPartition(7);
                  }}
                  ondragend={() => {
                    band.selectDraggingPartition(0);
                  }}
                  dim={band.sigGetVDim()}
                  rotation={band.sigGetRotation()}
                  selected={
                    Number(band.sigGetEditing()) === 7 ? 'true' : 'false'
                  }
                />
                <Show when={band.sigGetRotation() < 180}>
                  <BandEnd1
                    classCSS="cursor-pointer"
                    styleCSS={
                      'height:' +
                      band.sigGetVDim() +
                      'px;max-width:' +
                      band.sigGetVDim() +
                      'px;max-height:' +
                      band.sigGetVDim() +
                      'px;'
                    }
                    styleSVG={'height:100%;'}
                    height={band.sigGetVDim()}
                    rotation={band.sigGetRotation() - 90}
                  />
                </Show>
                <Show when={band.sigGetRotation() >= 180}>
                  <BandEnd0
                    classCSS="cursor-pointer"
                    styleCSS={
                      'height:' +
                      band.sigGetVDim() +
                      'px;max-width:' +
                      band.sigGetVDim() +
                      'px;max-height:' +
                      band.sigGetVDim() +
                      'px;'
                    }
                    styleSVG={'height:100%;'}
                    height={band.sigGetVDim()}
                    rotation={band.sigGetRotation() + 90}
                  />
                </Show>
              </div>
            </div>
          </Show>
          <div
            id="editor"
            style={
              'width:' +
              (band.isVertical() === false ? '100%' : 'calc(100% - 86px);')
            }
          >
            <div id="partitionedit" class="mx-auto flex max-w-fit flex-row">
              <PartitionComponent
                id="partition0"
                classCSS="shrink cursor-pointer m-2 border-[3px] border-black touch-none"
                styleCSS={'width:512px;max-width:512px;max-height:512px;'}
                styleSVG={'width:100%;'}
                dim="512"
                rotation={band.sigGetRotation()}
                ondrop={(e) => {
                  e.preventDefault();
                  band.addFiles(
                    e.target.files || (e.dataTransfer && e.dataTransfer.files),
                    0
                  );
                }}
                ondragover={(e) => {
                  e.preventDefault();
                }}
                onpointerdown={(e) => {
                  band.pointerDown(e);
                }}
                onpointermove={(e) => {
                  band.pointerMove(e);
                }}
                onpointerup={(e) => {
                  band.pointerUp(e);
                }}
                onpointerleave={(e) => {
                  band.pointerLeave(e);
                }}
              />
            </div>
            <div
              id="toolbuttons"
              class={
                'flex justify-center ' +
                (band.bandProps.alpha === 'true' ? 'flex-wrap' : '')
              }
            >
              <button
                class={
                  'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                  (band.sigGetImageOperationType() ===
                  ImageOperationType.Gallery
                    ? 'bg-wearsharemagenta'
                    : 'bg-wearsharepink')
                }
                onClick={() => {
                  band.sigSetImageOperationType(ImageOperationType.Gallery);
                }}
              >
                <GalleryIcon width="100%" height="" />
              </button>
              <button
                class={
                  'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                  (band.sigGetImageOperationType() ===
                  ImageOperationType.PositionScaleRotate
                    ? 'bg-wearsharemagenta'
                    : 'bg-wearsharepink')
                }
                onClick={() => {
                  band.sigSetImageOperationType(
                    ImageOperationType.PositionScaleRotate
                  );
                }}
              >
                <MoveIcon
                  title="Position, Scale & Rotate"
                  width="100%"
                  height=""
                />
              </button>
              <Show when={band.bandProps.alpha === 'true'}>
                <button
                  class={
                    'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                    (band.sigGetImageOperationType() === ImageOperationType.Crop
                      ? 'bg-wearsharemagenta'
                      : 'bg-wearsharepink')
                  }
                  onClick={() => {
                    band.sigSetImageOperationType(ImageOperationType.Crop);
                  }}
                >
                  <CropIcon width="100%" height="" />
                </button>
              </Show>
              <button
                class={
                  'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                  (band.sigGetImageOperationType() ===
                  ImageOperationType.Background
                    ? 'bg-wearsharemagenta'
                    : 'bg-wearsharepink')
                }
                onClick={() => {
                  band.sigSetImageOperationType(ImageOperationType.Background);
                }}
              >
                <BackgroundIcon width="100%" height="" />
              </button>
              <button
                class={
                  'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                  (band.sigGetImageOperationType() ===
                  ImageOperationType.ColorBalance
                    ? 'bg-wearsharemagenta'
                    : 'bg-wearsharepink')
                }
                onClick={() => {
                  band.sigSetImageOperationType(
                    ImageOperationType.ColorBalance
                  );
                }}
              >
                <BrightnessContrastIcon width="100%" height="" />
              </button>
              <Show when={band.bandProps.alpha === 'true'}>
                <button
                  class={
                    'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                    (band.sigGetImageOperationType() ===
                    ImageOperationType.Sticker
                      ? 'bg-wearsharemagenta'
                      : 'bg-wearsharepink')
                  }
                  onClick={() => {
                    band.sigSetImageOperationType(ImageOperationType.Sticker);
                  }}
                >
                  <StickerIcon width="100%" height="" />
                </button>
                <button
                  class={
                    'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                    (band.sigGetImageOperationType() ===
                    ImageOperationType.Effect
                      ? 'bg-wearsharemagenta'
                      : 'bg-wearsharepink')
                  }
                  onClick={() => {
                    band.sigSetImageOperationType(ImageOperationType.Effect);
                  }}
                >
                  <EffectIcon width="100%" height="" />
                </button>
                <button
                  class={
                    'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                    (band.sigGetImageOperationType() ===
                    ImageOperationType.Posterize
                      ? 'bg-wearsharemagenta'
                      : 'bg-wearsharepink')
                  }
                  onClick={() => {
                    band.sigSetImageOperationType(ImageOperationType.Posterize);
                  }}
                >
                  <PosterizeIcon width="100%" height="" />
                </button>
                <button
                  class={
                    'm-1 max-w-[123px] cursor-pointer rounded-lg border-[3px] border-black shadow-lg ' +
                    (band.sigGetImageOperationType() ===
                    ImageOperationType.SharpenBlur
                      ? 'bg-wearsharemagenta'
                      : 'bg-wearsharepink')
                  }
                  onClick={() => {
                    band.sigSetImageOperationType(
                      ImageOperationType.SharpenBlur
                    );
                  }}
                >
                  <SharpenBlurIcon width="100%" height="" />
                </button>
              </Show>
            </div>
            <div id="parametercontrols" class="mx-auto flex justify-center">
              <Switch>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.Gallery
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">
                        Choose an image from your gallery
                      </p>
                    </div>
                    <div class="flex flex-wrap">
                      <For each={band.sigGetGallery()}>
                        {(item) => (
                          <button
                            title={item.title}
                            class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px] w-[80px] min-w-[80px] h-[80px] min-h-[80px]"
                            onClick={() => {
                              band.partitionSetImgLocalFilename(
                                item.local_filename,
                                item.scale,
                                item.imageid,
                                item.thumb_dataurl,
                                0
                              );
                            }}
                          >
                            <img height="64" src={item.thumb_dataurl} />
                          </button>
                        )}
                      </For>
                    </div>
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.PositionScaleRotate
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Position Image</p>
                      <button
                        id="resetposition"
                        class="m-1 cursor-pointer rounded-lg bg-wearsharelime shadow-lg"
                        onClick={() => {
                          band.partitionSetImgPosition(
                            defaultPartitionProps.imgtranslatex,
                            defaultPartitionProps.imgtranslatey,
                            defaultPartitionProps.imgscale,
                            defaultPartitionProps.imgrotation,
                            0,
                            true
                          );
                        }}
                      >
                        <ResetSettingsIcon title="Reset Position" width="48" />
                      </button>
                    </div>
                    <label for="transx" class="mb-1 block">
                      Left &lt;--&gt; Right
                    </label>
                    <input
                      id="transx"
                      name="transx"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imgtranslatex}
                      max={maxPartitionProps.imgtranslatex}
                      step={stepPartitionProps.imgtranslatex}
                      value={band.partitionGetImgTranslateX(0)}
                      onInput={(e) => {
                        band.partitionSetImgTranslateX(
                          e.target.value,
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgTranslateX(e.target.value, 0, true);
                      }}
                    />
                    <label for="transy" class="mb-1 block">
                      Up &lt;--&gt; Down
                    </label>
                    <input
                      id="transy"
                      name="transy"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imgtranslatey}
                      max={maxPartitionProps.imgtranslatey}
                      step={stepPartitionProps.imgtranslatey}
                      value={band.partitionGetImgTranslateY(0)}
                      onInput={(e) => {
                        band.partitionSetImgTranslateY(
                          e.target.value,
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgTranslateY(e.target.value, 0, true);
                      }}
                    />
                    <label for="zoom" class="mb-1 block">
                      Zoom / Scale
                    </label>
                    <input
                      id="zoom"
                      name="zoom"
                      type="range"
                      class="range-slider"
                      min="0"
                      max="2"
                      step="0.01"
                      value={DynamicBand.mapToRange(
                        band.partitionGetImgScale(0),
                        minPartitionProps.imgscale,
                        defaultPartitionProps.imgscale,
                        maxPartitionProps.imgscale
                      )}
                      onInput={(e) => {
                        band.partitionSetImgScale(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgscale,
                            defaultPartitionProps.imgscale,
                            maxPartitionProps.imgscale
                          ),
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgScale(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgscale,
                            defaultPartitionProps.imgscale,
                            maxPartitionProps.imgscale
                          ),
                          0,
                          true
                        );
                      }}
                    />
                    <label for="rotate" class="mb-1 block">
                      Rotate
                    </label>
                    <input
                      id="rotate"
                      name="rotate"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imgrotation}
                      max={maxPartitionProps.imgrotation}
                      step={stepPartitionProps.imgrotation}
                      value={band.partitionGetImgRotation(0)}
                      onInput={(e) => {
                        band.partitionSetImgRotation(e.target.value, 0, false);
                      }}
                      onChange={(e) => {
                        band.partitionSetImgRotation(e.target.value, 0, true);
                      }}
                    />
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.Background
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Background Color</p>
                      <button
                        id="resetposition"
                        class="m-1 cursor-pointer rounded-lg bg-wearsharelime shadow-lg"
                        onClick={() => {
                          band.partitionSetBgColor(
                            defaultPartitionProps.bgcolor,
                            0,
                            true
                          );
                        }}
                      >
                        <ResetSettingsIcon
                          title="Reset Background Color"
                          width="48"
                        />
                      </button>
                    </div>
                    <label for="background" class="mb-1 block">
                      click to select color
                    </label>
                    <input
                      id="background"
                      name="background"
                      type="color"
                      class="h-[128px] w-[128px]"
                      value={band.partitionGetBgColor(0)}
                      onInput={(e) => {
                        band.partitionSetBgColor(e.target.value, 0, false);
                      }}
                      onChange={(e) => {
                        band.partitionSetBgColor(e.target.value, 0, true);
                      }}
                    />
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() === ImageOperationType.Crop
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Choose a Crop Frame</p>
                    </div>
                    <div class="flex flex-wrap">
                      <button
                        title="None"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(CropFrame.none, 0);
                        }}
                      >
                        &nbsp;
                      </button>
                      <button
                        title="Crop Circle"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(CropFrame.circle, 0);
                        }}
                      >
                        <CropCircleIcon />
                      </button>
                      <button
                        title="Crop Oval"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(CropFrame.oval, 0);
                        }}
                      >
                        <CropOvalIcon />
                      </button>
                      <button
                        title="Crop Square"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(CropFrame.square, 0);
                        }}
                      >
                        <CropSquareIcon />
                      </button>
                      <button
                        title="Crop Splash"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(CropFrame.splash, 0);
                        }}
                      >
                        <CropSplashIcon />
                      </button>
                      <button
                        title="Crop Star"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(CropFrame.star, 0);
                        }}
                      >
                        <CropStarIcon />
                      </button>
                      <button
                        title="Crop Back Square"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(
                            CropFrame.backsquare,
                            0
                          );
                        }}
                      >
                        <CropBackSquareIcon />
                      </button>
                      <button
                        title="Crop Forward Square"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 w-[64px] min-w-[64px] h-[64px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgCropFrame(
                            CropFrame.forwardsquare,
                            0
                          );
                        }}
                      >
                        <CropForwardSquareIcon />
                      </button>
                    </div>
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.Sticker
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Choose a sticker to apply</p>
                    </div>
                    <div class="flex flex-wrap">
                      <button
                        title="none"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[48px] w-[64px] min-w-[64px]"
                        onClick={() => {
                          band.partitionSetImgSticker('', 0);
                        }}
                      ></button>
                      <For each={band.sigGetStickers()}>
                        {(item) => (
                          <button
                            title={item.title}
                            class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[48px] w-[64px] min-w-[64px]"
                            onClick={() => {
                              band.partitionSetImgSticker(
                                String.fromCodePoint(item.unicode),
                                0
                              );
                            }}
                          >
                            <img
                              src={PartitionProps.uriOf.get(item.unicode)}
                              width="64"
                              height="64"
                            />
                          </button>
                        )}
                      </For>
                    </div>
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.ColorBalance
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Adjust Color Balance</p>
                      <button
                        id="resetcolorbalance"
                        class="m-1 cursor-pointer rounded-lg bg-wearsharelime shadow-lg"
                        onClick={() => {
                          band.partitionSetImgColorBalance(
                            defaultPartitionProps.imgbrightness,
                            defaultPartitionProps.imgcontrast,
                            defaultPartitionProps.imghuerotate,
                            defaultPartitionProps.imgsaturate,
                            defaultPartitionProps.imggrayscale,
                            defaultPartitionProps.imgsepia,
                            0,
                            true
                          );
                        }}
                      >
                        <ResetSettingsIcon
                          title="Reset Color Balance"
                          width="48"
                        />
                      </button>
                    </div>
                    <label for="brightness" class="mb-1 block">
                      Brightness
                    </label>
                    <input
                      id="brightness"
                      name="brightness"
                      type="range"
                      class="range-slider"
                      min="0"
                      max="2"
                      step="0.01"
                      value={DynamicBand.mapToRange(
                        band.partitionGetImgBrightness(0),
                        minPartitionProps.imgbrightness,
                        defaultPartitionProps.imgbrightness,
                        maxPartitionProps.imgbrightness
                      )}
                      onInput={(e) => {
                        band.partitionSetImgBrightness(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgbrightness,
                            defaultPartitionProps.imgbrightness,
                            maxPartitionProps.imgbrightness
                          ),
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgBrightness(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgbrightness,
                            defaultPartitionProps.imgbrightness,
                            maxPartitionProps.imgbrightness
                          ),
                          0,
                          true
                        );
                      }}
                    />
                    <label for="contrast" class="mb-1 block">
                      Contrast
                    </label>
                    <input
                      id="contrast"
                      name="contrast"
                      type="range"
                      class="range-slider"
                      min="0"
                      max="2"
                      step="0.01"
                      value={DynamicBand.mapToRange(
                        band.partitionGetImgContrast(0),
                        minPartitionProps.imgcontrast,
                        defaultPartitionProps.imgcontrast,
                        maxPartitionProps.imgcontrast
                      )}
                      onInput={(e) => {
                        band.partitionSetImgContrast(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgcontrast,
                            defaultPartitionProps.imgcontrast,
                            maxPartitionProps.imgcontrast
                          ),
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgContrast(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgcontrast,
                            defaultPartitionProps.imgcontrast,
                            maxPartitionProps.imgcontrast
                          ),
                          0,
                          true
                        );
                      }}
                    />
                    <label for="hue" class="mb-1 block">
                      Hue
                    </label>
                    <input
                      id="hue"
                      name="hue"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imghuerotate}
                      max={maxPartitionProps.imghuerotate}
                      step={stepPartitionProps.imghuerotate}
                      value={band.partitionGetImgHueRotate(0)}
                      onInput={(e) => {
                        band.partitionSetImgHueRotate(e.target.value, 0, false);
                      }}
                      onChange={(e) => {
                        band.partitionSetImgHueRotate(e.target.value, 0, true);
                      }}
                    />
                    <label for="saturation" class="mb-1 block">
                      Saturation
                    </label>
                    <input
                      id="saturation"
                      name="saturation"
                      type="range"
                      class="range-slider"
                      min="0"
                      max="2"
                      step="0.01"
                      value={DynamicBand.mapToRange(
                        band.partitionGetImgSaturate(0),
                        minPartitionProps.imgsaturate,
                        defaultPartitionProps.imgsaturate,
                        maxPartitionProps.imgsaturate
                      )}
                      onInput={(e) => {
                        band.partitionSetImgSaturate(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgsaturate,
                            defaultPartitionProps.imgsaturate,
                            maxPartitionProps.imgsaturate
                          ),
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgSaturate(
                          DynamicBand.mapFromRange(
                            e.target.value,
                            minPartitionProps.imgsaturate,
                            defaultPartitionProps.imgsaturate,
                            maxPartitionProps.imgsaturate
                          ),
                          0,
                          true
                        );
                      }}
                    />
                    <label for="grayscale" class="mb-1 block">
                      Grayscale
                    </label>
                    <input
                      id="grayscale"
                      name="grayscale"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imggrayscale}
                      max={maxPartitionProps.imggrayscale}
                      step={stepPartitionProps.imggrayscale}
                      value={band.partitionGetImgGrayscale(0)}
                      onInput={(e) => {
                        band.partitionSetImgGrayscale(e.target.value, 0, false);
                      }}
                      onChange={(e) => {
                        band.partitionSetImgGrayscale(e.target.value, 0, true);
                      }}
                    />
                    <Show when={band.bandProps.alpha === 'true'}>
                      <label for="sepia" class="mb-1 block">
                        Sepia
                      </label>
                      <input
                        id="sepia"
                        name="sepia"
                        type="range"
                        class="range-slider"
                        min={minPartitionProps.imgsepia}
                        max={maxPartitionProps.imgsepia}
                        step={stepPartitionProps.imgsepia}
                        value={band.partitionGetImgSepia(0)}
                        onInput={(e) => {
                          band.partitionSetImgSepia(e.target.value, 0, false);
                        }}
                        onChange={(e) => {
                          band.partitionSetImgSepia(e.target.value, 0, true);
                        }}
                      />
                    </Show>
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.SharpenBlur
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Sharpen or blur image</p>
                      <button
                        id="resetposition"
                        class="m-1 cursor-pointer rounded-lg bg-wearsharelime shadow-lg"
                        onClick={() => {
                          band.partitionSetImgSharpenBlur(
                            defaultPartitionProps.imgsharpen,
                            defaultPartitionProps.imgblur,
                            0,
                            true
                          );
                        }}
                      >
                        <ResetSettingsIcon
                          title="Reset Sharpen and Blur"
                          width="48"
                        />
                      </button>
                    </div>
                    <label for="sharpen" class="mb-1 block">
                      Sharpen
                    </label>
                    <input
                      id="sharpen"
                      name="sharpen"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imgsharpen}
                      max={maxPartitionProps.imgsharpen}
                      step={stepPartitionProps.imgsharpen}
                      value={band.partitionGetImgSharpen(0)}
                      onInput={(e) => {
                        band.partitionSetImgSharpen(e.target.value, 0, false);
                      }}
                      onChange={(e) => {
                        band.partitionSetImgSharpen(e.target.value, 0, true);
                      }}
                    />
                    <label for="blur" class="mb-1 block">
                      Blur
                    </label>
                    <input
                      id="blur"
                      name="blur"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imgblur}
                      max={maxPartitionProps.imgblur}
                      step={stepPartitionProps.imgblur}
                      value={band.partitionGetImgBlur(0)}
                      onInput={(e) => {
                        band.partitionSetImgBlur(e.target.value, 0, false);
                      }}
                      onChange={(e) => {
                        band.partitionSetImgBlur(e.target.value, 0, true);
                      }}
                    />
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.Posterize
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Posterize Image</p>
                      <button
                        id="resetposition"
                        class="m-1 cursor-pointer rounded-lg bg-wearsharelime shadow-lg"
                        onClick={() => {
                          band.partitionSetImgPosterize(
                            defaultPartitionProps.imgposterize,
                            0,
                            true
                          );
                        }}
                      >
                        <ResetSettingsIcon
                          title="Reset Posterization"
                          width="48"
                        />
                      </button>
                    </div>
                    <label for="posterize" class="mb-1 block">
                      Posterize Levels
                    </label>
                    <input
                      id="posterize"
                      name="posterize"
                      type="range"
                      class="range-slider"
                      min={minPartitionProps.imgposterize}
                      max={maxPartitionProps.imgposterize}
                      step={stepPartitionProps.imgposterize}
                      value={
                        17 - Math.min(band.partitionGetImgPosterize(0), 16)
                      }
                      onInput={(e) => {
                        band.partitionSetImgPosterize(
                          17 - e.target.value,
                          0,
                          false
                        );
                      }}
                      onChange={(e) => {
                        band.partitionSetImgPosterize(
                          17 - e.target.value,
                          0,
                          true
                        );
                      }}
                    />
                  </div>
                </Match>
                <Match
                  when={
                    band.sigGetImageOperationType() ===
                    ImageOperationType.Effect
                  }
                >
                  <div class="w-[512px] shrink overflow-x-auto rounded-lg border-[3px] border-black bg-wearsharelime p-2">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold">Add an effect</p>
                    </div>
                    <div class="flex flex-wrap">
                      <button
                        title="none"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px] w-[80px] min-w-[80px] min-h-[64px]"
                        onClick={() => {
                          band.partitionSetImgEffect(Effect.none, 0);
                        }}
                      >
                        &nbsp;
                      </button>
                      <button
                        title="warm"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px]"
                        onClick={() => {
                          band.partitionSetImgEffect(Effect.warm, 0);
                        }}
                      >
                        &#x1F31E;
                      </button>
                      <button
                        title="vintage"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px]"
                        onClick={() => {
                          band.partitionSetImgEffect(Effect.vintage, 0);
                        }}
                      >
                        &#x1F39E;
                      </button>
                      <button
                        title="cool"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px]"
                        onClick={() => {
                          band.partitionSetImgEffect(Effect.cool, 0);
                        }}
                      >
                        &#x1F9CA;
                      </button>
                      <button
                        title="faded"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px]"
                        onClick={() => {
                          band.partitionSetImgEffect(Effect.faded, 0);
                        }}
                      >
                        &#x1FAE5;
                      </button>
                      <button
                        title="graphite"
                        class="bg-wearshareblue rounded-lg shadow-lg m-1 text-[64px]"
                        onClick={() => {
                          band.partitionSetImgEffect(Effect.graphite, 0);
                        }}
                      >
                        &#x270F;
                      </button>
                    </div>
                  </div>
                </Match>
              </Switch>
            </div>
            <div id="metabuttons" class="flex justify-center">
              <button
                class="m-1 max-w-[88px] cursor-pointer rounded-lg border-[3px] border-black bg-wearsharepurple shadow-lg"
                onClick={() => {
                  band.partitionUndo(0);
                }}
              >
                <UndoIcon width="100%" height="" />
              </button>
              <button
                class="m-1 max-w-[88px] cursor-pointer rounded-lg border-[3px] border-black bg-wearsharepurple shadow-lg"
                onClick={() => {
                  band.partitionRedo(0);
                }}
              >
                <RedoIcon width="100%" height="" />
              </button>
            </div>
            <div class="mx-auto flex justify-center">
              <div class="m-1 w-[512px] shrink rounded-lg border-[2px] border-black p-1 text-sm">
                <input
                  class="w-full bg-wearsharewhite p-2 text-center"
                  type="text"
                  value={band.sigGetName()}
                  placeholder="TITLE YOUR DESIGN"
                  title="Title your design"
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (!value || value.length === 0) {
                      document.getElementById('nameerror').textContent =
                        'Give your band a memorable name!';
                    } else {
                      if (!band.setName(value)) {
                        document.getElementById('nameerror').textContent =
                          'Please choose a name you have not already used';
                      } else {
                        document.getElementById('nameerror').textContent = '';
                      }
                    }
                  }}
                  onInput={(e) => {
                    const value = e.target.value.trim();
                    if (!value || value.length === 0) {
                      document.getElementById('nameerror').textContent =
                        'Give your band a memorable name!';
                    } else {
                      if (!band.setName(value, false)) {
                        document.getElementById('nameerror').textContent =
                          'Please choose a name you have not already used';
                      } else {
                        document.getElementById('nameerror').textContent = '';
                      }
                    }
                  }}
                />
                <br />
                <span
                  id="nameerror"
                  class="w-[70%] max-w-[512px] text-red-600"
                ></span>
              </div>
            </div>
            <Show when={band.bandProps.alpha === 'true'}>
              <div class="mx-auto flex justify-center">
                <span class="text-xs uppercase">
                  Add a hidden message, revealed through the QR scan code
                </span>
              </div>
              <div class="mx-auto flex justify-center">
                <div class="rounded-lg border-black border-[2px] text-sm m-1 p-1 w-[70%] max-w-[512px] shrink">
                  <textarea
                    class="text-center w-full p-2"
                    rows="3"
                    value={band.sigGetSecret()}
                    placeholder="SECRET MESSAGE"
                    title="Add a hidden message, revealed through the QR scan code"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      band.setSecret(value);
                    }}
                    onInput={(e) => {
                      const value = e.target.value.trim();
                      band.setSecret(value, false);
                    }}
                  />
                  <br />
                  <span
                    id="secreterror"
                    class="text-red-600 w-[70%] max-w-[512px]"
                  ></span>
                </div>
              </div>
            </Show>
            <div class="mx-auto flex justify-center">
              <div class="m-1 w-[512px] shrink rounded-lg border-[2px] border-black p-1 text-sm">
                <input
                  class="w-full bg-wearsharewhite p-2 text-center"
                  type="text"
                  value={band.sigGetEmail()}
                  placeholder="EMAIL ADDRESS (*required)"
                  title="contact email address"
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (!value || value.length < 3) {
                      document.getElementById('emailerror').textContent =
                        'Email is required to save your design';
                    } else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                      document.getElementById('emailerror').textContent =
                        'Please enter a valid email address';
                    } else {
                      document.getElementById('emailerror').textContent = '';
                      band.setEmail(value);
                    }
                  }}
                  onInput={(e) => {
                    const value = e.target.value.trim();
                    if (!value || value.length < 3) {
                      document.getElementById('emailerror').textContent =
                        'Email is required to save your design';
                    } else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                      document.getElementById('emailerror').textContent =
                        'Please enter a valid email address';
                    } else {
                      document.getElementById('emailerror').textContent = '';
                      band.setEmail(value, false);
                    }
                  }}
                />
                <br />
                <span
                  id="emailerror"
                  class="w-[70%] max-w-[512px] text-red-600"
                ></span>
              </div>
            </div>
            <Show when={band.bandProps.alpha === 'true'}>
              <div class="mx-auto flex justify-center">
                <div class="m-1 w-[512px] shrink rounded-lg border-[2px] border-black p-1 text-sm">
                  <input
                    class="w-full bg-wearsharewhite p-2 text-center"
                    type="text"
                    value={band.sigGetPhone()}
                    placeholder="PHONE NUMBER"
                    title="contact phone number"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (!value || value.length < 3) {
                        document.getElementById('phoneerror').textContent =
                          'Phone number is required to save your design';
                      } else if (!value.match(/\d+/)) {
                        document.getElementById('phoneerror').textContent =
                          'Please enter a valid phone number';
                      } else {
                        document.getElementById('phoneerror').textContent = '';
                        band.setPhone(value);
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value.trim();
                      if (!value || value.length < 3) {
                        document.getElementById('phoneerror').textContent =
                          'Phone number is required to save your design';
                      } else if (!value.match(/\d+/)) {
                        document.getElementById('phoneerror').textContent =
                          'Please enter a valid phone number';
                      } else {
                        document.getElementById('phoneerror').textContent = '';
                        band.setPhone(value, false);
                      }
                    }}
                  />
                  <br />
                  <span
                    id="phoneerror"
                    class="w-[70%] max-w-[512px] text-red-600"
                  ></span>
                </div>
              </div>
              <div class="mx-auto flex justify-center">
                <button
                  class={
                    (band.isComplete() && !band.sigGetIsSubmitting()
                      ? 'bg-wearsharelime'
                      : 'bg-gray-200') +
                    ' rounded-lg border-black border-[2px] text-sm uppercase m-1 p-4'
                  }
                  title="Save my band to the shopping cart"
                  onClick={() => {
                    band.saveOnClient();
                  }}
                >
                  {band.sigGetIsSubmitting() ? (
                    <span class="flex items-center justify-center space-x-2">
                      <div class="animate-spin h-5 w-5 border-2 border-black border-t-transparent rounded-full" />
                      <span>Sending...</span>
                    </span>
                  ) : (
                    'Create using Client'
                  )}
                </button>
              </div>
            </Show>
            <div class="mx-auto flex justify-center">
              <span
                id="partitionerror"
                class="text-red-600 w-[70%] max-w-[512px]"
              >
                {band.completionMessage()}
              </span>
            </div>
            <Show when={band.sigGetIsSubmitting()}>
              <div class="mx-auto flex justify-center">
                <div class="bg-wearsharewhite border-black border-[2px] w-[256px] h-[36px]">
                  <div
                    class="bg-wearshareroyal h-[32px]"
                    style={'height:32px;width:' + band.sigGetProgress() + '%;'}
                  />
                </div>
              </div>
            </Show>
            <div class="mx-auto flex justify-center">
              <button
                class={
                  (band.isComplete() && !band.sigGetIsSubmitting()
                    ? 'bg-wearsharelime'
                    : 'bg-gray-200') +
                  ' rounded-lg border-black border-[2px] text-sm uppercase m-1 p-4'
                }
                title="Save my band to the shopping cart"
                onClick={() => {
                  band.saveOnWorker();
                }}
              >
                {band.sigGetIsSubmitting() ? (
                  <span class="flex items-center justify-center space-x-2">
                    <div class="animate-spin h-5 w-5 border-2 border-black border-t-transparent rounded-full" />
                    <span>Sending...</span>
                  </span>
                ) : (
                  'Create'
                )}
              </button>
            </div>
            <div class="mx-auto flex justify-center">
              <span class="text-xs uppercase">
                Design changes require creating a new band
              </span>
            </div>
          </div>
        </div>
        <button
          id="partition0clear"
          class="invisible absolute right-[4%] top-[4%] bg-wearshareblue rounded-lg border-black border-[3px] shadow-lg m-1 w-fit h-fit"
          style={
            'left: ' +
            parseInt(band.sigGetEditPartitionX()).toString() +
            'px;' +
            'top: ' +
            (
              band.sigGetEditPartitionY() +
              Math.round(0.04 * band.sigGetEditPartitionH())
            ).toString() +
            'px;'
          }
          onClick={() => {
            band.partitionClear(0);
          }}
        >
          <ClearIcon title="Clear" width="32" />
        </button>
        <div
          id="partition0add"
          class="invisible absolute left-[4%] bottom-[4%] w-fit h-fit"
        >
          <label
            class="inline-block relative m-0 cursor-pointer"
            for="uploadimageoperation"
          >
            <div
              class="m-1"
              onDrop={(e) => {
                e.preventDefault();
                band.addFiles(
                  e.target.files || (e.dataTransfer && e.dataTransfer.files),
                  0
                );
              }}
              onDragover={(e) => {
                e.preventDefault();
              }}
            >
              <PlusIcon title="Upload Image" width="40" />
            </div>
            <input
              id="uploadimageoperation"
              type="file"
              class="absolute opacity-[0] top-0 left-0 bottom-0 w-1"
              onChange={(event) => {
                band.addFiles(event.target.files, 0);
              }}
              multiple
              accept="image/*"
            />
          </label>
        </div>
      </Show>
    </>
    // *INDENT-ON*
  );
};
